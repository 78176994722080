<template>


<div class="overflow-scroll w-full  scroll-smooth" style="scrollbar-width: none">
  <div v-if="editor">

    <!-- <div v-if="loading" class="dots-6 scale-75 top-3 left-5 fixed"></div> -->

    <div class="absolute h-10 top-24 left-10 -z-90 opacity-0 hover:opacity-100 flex">
      <button class="p-2 m-1 w-8 h-8  btn btnbg opacity-40 hover:opacity-100" @click="generateCover()">
          <div class="p-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24"><path fill="grey" d="m8.5 13.5l2.5 3l3.5-4.5l4.5 6H5m16 1V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2Z"/></svg>
          </div>
        </button>
      <button class="p-2 m-1 w-8 h-8 text-neutral-500 text-sm btn btnbg opacity-40 hover:opacity-100" @click="generateHeading()">
          <div class="p-2">H</div>
        </button>
    </div>


    <bubble-menu class="bg-neutral-300 p-4 pl-12 pr-12 border border-neutral-300 justify-center items-center text-neutral-100 h-8 rounded-full flex fill-red shadow-xl"
      :editor="editor"
      :tippy-options="{ duration: 100 }"
      v-if="editor">

      <button class="p-2 pt-1 text-neutral-700 text-sm pb-1" @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        ¶
      </button>

      <button class="p-2 pt-1 text-neutral-700 text-sm pb-1" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        H1
      </button>
      <button class="p-2 pt-1 text-neutral-700 text-sm pb-1" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        H2
      </button>
      <button class="p-2 pt-1 pb-1 text-neutral-700 text-sm font-bold" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        B
      </button>

      <button class="p-2 pt-1 pb-1 text-neutral-700 italic" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        I
      </button>

      <button >
      <svg class="w-2 h-5 stroke-1" viewBox="0 0 2048 2048"><path fill="lightgrey" d="M1024 128v1792H896V128h128z"/></svg>
      </button>



      <button @click="showLanguages = !showLanguages" class="tooltip-button p-3 pt-1 pb-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#404040" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
        </svg>
        <span v-if="!showLanguages" class="tooltip-text">Translate</span>
        <div v-if="showLanguages" class="flex flex-col bg-neutral-300 rounded-lg absolute mb-6 bottom-1/2 -left-1/2 items-start justify-start">
          
          <span @click="Translate('Polish')" class="m-1 text-neutral-800">Polish</span>
          <span @click="Translate('Swedish')" class="m-1 text-neutral-800">Swedish</span>
          <span @click="Translate('Norwegian')" class="m-1 text-neutral-800">Norwegian</span>
          <span @click="Translate('Finnish')" class="m-1 text-neutral-800">Finnish</span>
          <span @click="Translate('Danish')" class="m-1 text-neutral-800">Danish</span>
          <span @click="Translate('Dutch')" class="m-1 text-neutral-800">Dutch</span>
          <span @click="Translate('Portuguese')" class="m-1 text-neutral-800">Portuguese</span>
          <span @click="Translate('Spanish')" class="m-1 text-neutral-800">Spanish</span>
          <span @click="Translate('French')" class="m-1 text-neutral-800">French</span>
          <span @click="Translate('Italian')" class="m-1 text-neutral-800">Italian</span>
          <span @click="Translate('German')" class="m-1 text-neutral-800">German</span>
          <span @click="Translate('English')" class="m-1 text-neutral-800">English</span>         
        </div>
      </button>

      <button class="tooltip-button p-2 pt-1 pb-1 pe-3" @click="Summarize()" :class="{ 'is-active': editor.isActive('textColor', { color: 'red' }) }">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 stroke-2" viewBox="0 0 24 24"><path fill="#404040" d="M12 15q1.25 0 2.125-.875T15 12q0-1.25-.875-2.125T12 9q-1.25 0-2.125.875T9 12q0 1.25.875 2.125T12 15Zm0 7q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg>
        <span class="tooltip-text">Summarize</span>
      </button>


      <button @click="showGrammarOptions = !showGrammarOptions" class="tooltip-button p-3 pt-1 pb-1 text-neutral-700">
        G
        <span v-if="!showGrammarOptions" class="tooltip-text">Fix Grammar</span>
        <div v-if="showGrammarOptions" class="flex flex-col bg-neutral-300 rounded-lg absolute mb-6 bottom-1/2 -left-1/2 items-start justify-start">

          
          
          <span @click="fixGrammar('Word Choice')" class="m-1 text-neutral-800">Word Choice</span>
          <span @click="fixGrammar('Word Repetition')" class="m-1 truncate text-neutral-800">Word Repetition</span>
          <span @click="fixGrammar('Punctuation')" class="m-1 text-neutral-800">Punctuation</span>
          <span @click="fixGrammar('Capitalization')" class="m-1 text-neutral-800">Capitalization</span>   
          <span @click="fixGrammar('Spelling')" class="m-1 text-neutral-800">Spelling</span> 
          <span @click="fixGrammar('All')" class="m-1 text-neutral-800">All</span>

        </div>
      </button>

      <button class="tooltip-button p-2 pt-1 pb-1 pe-2" @click="notesToText()" :class="{ 'is-active': editor.isActive('textColor', { color: 'red' }) }">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 stroke-2" viewBox="0 0 24 24"><path fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 6h14M5 10h10M5 14h14M5 18h6"/></svg>
          <span class="tooltip-text">Notes to Text</span>
      </button>

      <button  @click="Rewrite()" class="tooltip-button p-3 pt-1 pb-1">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 stroke-2" viewBox="0 0 24 24"><path fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13.5A7.5 7.5 0 1 1 11.5 6H20m0 0l-3-3m3 3l-3 3"/></svg>
        <span class="tooltip-text">Rewrite</span>
      </button>

    </bubble-menu> 

    <!-- <floating-menu class="left-5" :editor="editor" :tippy-options="{ duration: 200 }" v-if="editor.getText().length > 5">

      <button @click="showFloatingMenu = !showFloatingMenu" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        <svg class="w-5 h-5" viewBox="0 0 256 256"><path fill="currentColor" d="M128 100a28 28 0 1 0 28 28a28.1 28.1 0 0 0-28-28Zm0 48a20 20 0 1 1 20-20a20.1 20.1 0 0 1-20 20Zm-80-48a28 28 0 1 0 28 28a28.1 28.1 0 0 0-28-28Zm0 48a20 20 0 1 1 20-20a20.1 20.1 0 0 1-20 20Zm160-48a28 28 0 1 0 28 28a28.1 28.1 0 0 0-28-28Zm0 48a20 20 0 1 1 20-20a20.1 20.1 0 0 1-20 20Z"/></svg>
      </button>

      <input class="h-8 rounded-full w-full" v-if="showFloatingMenu" type="text">

    </floating-menu> -->

    <div class="mt-28">
      <editor-content @keydown.space="handleSpace()" @keydown.left.right="handleArrow()" @keydown.enter="handleEnter()" @input="TiptapInput($event)" @keydown.prevent.tab="handleTab()" :editor="editor" />
    </div>

  </div>
</div>


</template>

<script>
import {  collection, addDoc, serverTimestamp, getDoc, doc, updateDoc, } from "firebase/firestore";
import { db } from '@/firebase/config'
import Document from '@tiptap/extension-document'
import StarterKit from '@tiptap/starter-kit'
import { BubbleMenu, Editor, EditorContent, FloatingMenu } from '@tiptap/vue-3'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import Placeholder from '@tiptap/extension-placeholder'
import Highlight from '@tiptap/extension-highlight'
import { Node } from '@tiptap/core'
import { Mark } from '@tiptap/core'
import Heading from '@tiptap/extension-heading'
import Completion from '../composables/Completion'
import Edit from '@/composables/Edit'
import Image from '@tiptap/extension-image'
import GenerateImage from '../composables/GenerateImage'
import Paragraph from '@tiptap/extension-paragraph'
import tippy from 'tippy.js'
import {debounce} from 'debounce'
import { LanguageGlobal, useLanguageGlobal} from '../composables/useLanguageGlobal'
import getUser from '../composables/getUser'


const CustomDocument = Document.extend({
  content: 'heading block*',
})


const AutocompleteNode = Node.create({
  name: 'AutocompleteNode',
  group: 'inline',
  inline: true,
  content: 'text*',
  atom: false,
  selectable: false,
  parseHTML() {
    return [
      {
        tag: 'custom-node',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes, 0]
  },
  // Set HTML attributes on the node when it’s created.
  addAttributes() {
    return {
      class: 'custom-node',
    }
  },

})


export default { 
  name: 'TipTap',
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  autoCompletion: {
      type: String,
      default: '',
    },
    undo: {
      type: Boolean,
      default: false,
    },
    redo: {
      type: Boolean,
      default: false,
    },
    changeFont: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'update:cursorPosition', 'trigger:autoCompletion', 'TextBeforeCursor', 'TextAfterCursor', 'update:loading'],

  data() {
    return {
      editor: null,
      Autocompletion: null,
      Grey: null,  
      PendingAutocompletion: null,   
      PendingRewrite: null,
      ContentBeforeInsertion: null, 
      Selection: null,
      Cursorposition: null,
      styledAutoCompletion: null,
      styledRewrite: null,
      CursorpositionBeforeInsertion: null,
      showLanguages: false,
      showFloatingMenu: false,
      sans: true,
      serif: false,
      mono: false,
      loading: false,
      typedCharacters: 0,
      HeadingPlaceholder: '',
      typedDots: 0,
      lastTypedCharacter: '',
      showLanguages: false,
      showGrammarOptions: false,
      LanguageDetected: false,
      completionRef: null,
      lastAutocomplete: null,
      lastAutocompletePrompt: null,
      lastAutocompleteSuffix: null,
      lastAutocompleteModel: null,
      obliquePrompts: [
        // A list of prompts in the style of oblique strategies:
        'Abandon normal instruments',
        'Accept advice',
        'Accretion',
        'Allow an easement (an easement is the abandonment of a stricture)',
        'Are there sections? Consider transitions',
        'Ask people to work against their better judgement',
        'Ask your body',
        'Balance the consistency principle with the inconsistency principle',
        'Breathe more deeply',
        'Bridges -build -burn',
        'Cascades',
        'Change ambiguities to specifics',
        'Change instrument roles',
        'Change nothing and continue consistently',
        'Change specifics to ambiguities',
        'Children -speaking -singing',
        'Cluster analysis',
        'Consider different fading systems',
        'Consult other sources -promising -unpromising',
        'Convert a melodic element into a rhythmic element',
        'Courage!',
        'Cut a vital connection',
        'Decorate, decorate',
        'Define an area as "safe" and use it as an anchor',
        'Destroy -nothing -the most important thing',
        'Discard an axiom',
        'Disconnect from desire',
        'Discover the recipes you are using and abandon them',  
        'Distorting time',  
        'Do nothing for as long as possible',
        'Do something boring',
        'Do the last thing first',
        'Do the washing up',
        'Do we need holes?',
        'Dont avoid what is easy',
        'Dont be afraid of things because theyre easy to do',
        'Dont be frightened of cliches',
        'Dont be frightened to display your talents',
        'Dont break the silence',
        'Dont stress one thing more than another',
        'Emphasize differences',
        'Emphasize repetitions',
        'Emphasize the flaws',
        'Faced with a choice, do both',
        'Feedback recordings into an acoustic situation',
        'Fill every beat with something',
        'Find a safe part and use it as an anchor',
        'Ghost echoes',

      ],
     
      updateLanguageGlobal: useLanguageGlobal().updateLanguageGlobal,
      LanguageGlobal: useLanguageGlobal().LanguageGlobal,
      user: getUser().user,
    }
  },
  methods: {

    setFontFamily() {
      // If sans is true, set font to serif
      if (this.sans && !this.serif && !this.mono) {
        // Get the current cursor position
        let Cursor = this.Cursorposition
        this.editor.chain().focus().selectAll().setFontFamily('SuisseMono').run()
        this.serif = false
        this.sans = false
        this.mono = true
        // Unselect and put the cursor back to the last position
        this.editor.commands.setTextSelection(Cursor)

      }
      // If serif is true, set font to mono
      else if (!this.sans && !this.serif && this.mono) {
        // Get the current cursor position
        let Cursor = this.Cursorposition
        this.editor.chain().focus().selectAll().setFontFamily('SuisseWorks').run()
        this.serif = true
        this.sans = false
        this.mono = false
        // Unselect and put the cursor back to the last position
        this.editor.commands.setTextSelection(Cursor)
      }
      // If mono is true, set font to sans
      else if (!this.sans && this.serif && !this.mono) {
        // Get the current cursor position
        let Cursor = this.Cursorposition
        this.editor.chain().focus().selectAll().setFontFamily('SuisseIntl').run()
        this.serif = false
        this.sans = true
        this.mono = false
        // Unselect and put the cursor back to the last position
        this.editor.commands.setTextSelection(Cursor)
      }
    },

    async generateHeading () {
      this.loading = true
      this.$emit('update:loading', this.loading)
      let content = this.editor.getText().slice(100, 1000)
      let titlePrompt
      if (this.LanguageGlobal === 'Deutsch' ) {
         titlePrompt = content + '\n\nGeneriere einen kurzen und prägnanten Titel, der aber nicht zu sehr den Inhalt bescheibt: '
      }
      else if (this.LanguageGlobal === 'English') {
       titlePrompt = content + '\n\nWrite a unique and short title for the story above that is not too descriptive: '
        }
      
      console.log('titlePrompt' + titlePrompt)
      const completion = await Completion(titlePrompt, "text-davinci-003", 30,'.', '' )    
      // Select the cursor to the beginning of the document
      this.editor.commands.setTextSelection(0)
      this.editor.commands.selectParentNode()
      this.editor.commands.deleteNode()
      this.editor.commands.insertContent({
        type: 'heading',
        attrs: {
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: completion.completion,
          },
        ],
      })
      this.loading = false
      this.$emit('update:loading', this.loading)
    },

    async chat () {

      // Remove the whole content of the editor
      this.editor.commands.clearContent()
      const completion = await Completion( 'This is a conversation between an AI assistant and a person. The goal of the conversation is to find out what the person might want to write about. \n\nUser: \n\nAI: ', "text-davinci-003", 100, '?', '' );        

      // Exchange the current selection with the completion

      this.editor.chain().focus().setBlockquote().insertContent('<span style="color: rgb(158, 158, 158)">'+ completion.completion + '?</span>').run()
      this.editor.commands.setHorizontalRule()

    },

    async notesToText () {
      this.loading = true
      this.$emit('update:loading', this.loading)
      let prompt
      if (this.LanguageGlobal === 'Deutsch' ) {
        prompt = this.Selection + '\n\nSchreibe einen flüssige Version dieses Textes ohne weitere Informationen hinzuzufügen: \n\n'
      }
      else if (this.LanguageGlobal === 'English') {
        prompt = this.Selection +  '\n\nWrite a fluent version from the text above without adding any extra information and keep the same tone of voice: '
        }
      console.log('notesToText prompt: ' + prompt)
      const completion = await Completion( prompt, "text-davinci-003", 1000,'', '' );        

      // Exchange the current selection with the completion
      this.loading = false
      this.$emit('update:loading', this.loading)
      this.InsertRewrite(completion.completion)

      },

      async generateCover() {   
      this.loading = true
      this.$emit('update:loading', this.loading)
      let content = this.editor.getText().slice(0, 600)
      
      const imagePrompt = await Completion( content  + '\n\nCreate an image prompt from the text above for an article cover for an online design magazine. It should be calm and aesthetic and not contain any letters: ', "text-davinci-003", 300,'', '' );    
      console.log('imagePrompt: ', imagePrompt.completion)
      const response = await GenerateImage(imagePrompt.completion, 1 );
      this.editor.commands.setTextSelection(0)
      this.editor.chain().focus().setImage({ src: response.ImageURLs }).run()
      this.loading = false
      this.$emit('update:loading', this.loading)

      },

    async insertImage() {
      this.loading = true
      this.$emit('update:loading', this.loading)
      // Get Text before cursor
      let TextBeforeCursor = this.editor.getText().slice(0, this.Cursorposition )
      console.log('TextBeforeCursor: ', TextBeforeCursor)

      // Slice textBeforeCursor so it's max 500 characters long
      TextBeforeCursor = TextBeforeCursor.slice(-500)
      
      console.log('Selection: ', this.Selection)

      const imagePrompt = await Completion( this.Selection  + '\n\nCreate an image prompt from the text above and explicitly say it should not contain letters. And it should also look aesthetic: ', "text-davinci-003", 300,'', '' );    
      console.log('imagePrompt: ', imagePrompt.completion)

      const response = await GenerateImage(imagePrompt.completion, 1 );
      this.editor.commands.setTextSelection(this.Cursorposition)

      this.editor.chain().focus().setImage({ src: response.ImageURLs }).run()

      this.loading = false
      this.$emit('update:loading', this.loading)
    },

    async Summarize () {
      this.loading = true
      this.$emit('update:loading', this.loading)
      console.log('Selection for summarization: ', this.Selection)
      // Get length of selection and set the max tokens to a tenth of that
      let SummarizePrompt
      
      if (this.LanguageGlobal === 'Deutsch' ) {
        SummarizePrompt = this.Selection + '\n\nFasse diesen Text oben in einer klaren und prägnanten Art und Weise zusammen ohne wichtige Details zu auszulassen: '
      }
      else if (this.LanguageGlobal === 'English') {
        SummarizePrompt = this.Selection + '\n\nSummarize the text above in a clear and concise way without missing any important details: '
      }

      const maxTokens = Math.round(this.Selection.length / 10)
      const completion = await Completion( SummarizePrompt, "text-davinci-003", maxTokens,'', '', 0.7, 0, 1, 1 );        
      console.log('Summarization: ', completion.completion)

      // Exchange the current selection with the completion
      this.InsertRewrite(completion.completion)
      this.loading = false
      this.$emit('update:loading', this.loading)
    },

    async Extend () {
      this.loading = true
      this.$emit('update:loading', this.loading)
      const completion = await Completion( this.Selection + '\n\nWrite a longer form of the text above without adding completely new points: ', "text-davinci-003", 200,'', '' );        
      console.log('Summarization: ', completion.completion)

      // Exchange the current selection with the completion
      this.InsertRewrite(completion.completion)
      this.loading = false
      this.$emit('update:loading', this.loading)
    },

    async Translate (language) {
      this.loading = true
      this.$emit('update:loading', this.loading)
      let TranslatePrompt

      if (this.LanguageGlobal === 'Deutsch' ) {
        TranslatePrompt = this.Selection + '\n\nÜbersetze diesen Text auf '
      }
      else if (this.LanguageGlobal === 'English') {
        TranslatePrompt = this.Selection + '\n\nTranslate this text to '
      }

      const completion = await Completion( TranslatePrompt + language + ': ', "text-davinci-003", 500,'', '' );        
      this.InsertRewrite(completion.completion)

      this.loading = false
      this.$emit('update:loading', this.loading)
    },

    async Rewrite () {
      this.loading = true
      this.$emit('update:loading', this.loading)
      let maxTokens =  Math.round(this.Selection.length * 1.5 )
      let RephrasePrompt
      
      if (this.LanguageGlobal === 'Deutsch' ) {
        RephrasePrompt = this.Selection + '\n\Formuliere den Text oben neu ohne die Länge oder den Ton zu ändern: '
      }
      else if (this.LanguageGlobal === 'English') {
        RephrasePrompt = this.Selection + '\n\nRephrase the text above without changing the lenght or tone: '
      }

      console.log('RephrasePrompt: ', RephrasePrompt)
      console.log('maxTokens: ', maxTokens)

      const completion = await Completion( RephrasePrompt, "text-davinci-003", maxTokens ,'.', '' );        
      console.log('Rewrite completion: ', completion.completion)
      // Exchange the current selection with the completion
      //this.editor.chain().focus().deleteSelection().insertContent('<span style="color: rgb(158, 158, 158)">' + completion.completion + '</span>').run()
      this.InsertRewrite(completion.completion)
      this.loading = false
      this.$emit('update:loading', this.loading)

    },

    async fixGrammar (input) {
      this.loading = true
      this.$emit('update:loading', this.loading) 
      let maxTokens =  Math.round(this.Selection.length * 1.5 )
      let grammarPrompt

      if (input === 'Spelling') {
        if (this.LanguageGlobal === 'Deutsch' ) {
          grammarPrompt = this.Selection + '\n\nKorrigiere die Rechtschreibung im Text oben: '
        }
        else if (this.LanguageGlobal === 'English') {
          grammarPrompt = this.Selection + '\n\nFix the spelling in the text above: '
        }
      }

      else if (input === 'Punctuation') {
        if (this.LanguageGlobal === 'Deutsch' ) {
          grammarPrompt = this.Selection + '\n\nKorrigiere die Satzzeichen im Text oben: '
        }
        else if (this.LanguageGlobal === 'English') {
          grammarPrompt = this.Selection + '\n\nFix the punctuation in the text above: '
        }
      }

      else if (input === 'Capitalization') {
        if (this.LanguageGlobal === 'Deutsch' ) {
          grammarPrompt = this.Selection + '\n\nKorrigiere die Groß- und Kleinschreibung im Text oben: '
        }
        else if (this.LanguageGlobal === 'English') {
          grammarPrompt = this.Selection + '\n\nFix the capitalization in the text above: '
        }
      }

      else if (input === 'Grammar') {
        if (this.LanguageGlobal === 'Deutsch' ) {
          grammarPrompt = this.Selection + '\n\nKorrigiere die Grammatik im Text oben: '
        }
        else if (this.LanguageGlobal === 'English') {
          grammarPrompt = this.Selection + '\n\nFix the grammar in the text above: '
        }
      }

      else if (input === 'Style') {
        if (this.LanguageGlobal === 'Deutsch' ) {
          grammarPrompt = this.Selection + '\n\nKorrigiere den Stil im Text oben: '
        }
        else if (this.LanguageGlobal === 'English') {
          grammarPrompt = this.Selection + '\n\nFix the style in the text above: '
        }
      }

      else if (input === 'Word Choice') {
        if (this.LanguageGlobal === 'Deutsch' ) {
          grammarPrompt = this.Selection + '\n\nKorrigiere die Wortwahl im Text oben: '
        }
        else if (this.LanguageGlobal === 'English') {
          grammarPrompt = this.Selection + '\n\nFix the word choice in the text above: '
        }
      }

      else if (input === 'Tense') {
        if (this.LanguageGlobal === 'Deutsch' ) {
          grammarPrompt = this.Selection + '\n\nKorrigiere die Zeitform im Text oben: '
        }
        else if (this.LanguageGlobal === 'English') {
          grammarPrompt = this.Selection + '\n\nFix the tense in the text above: '
        }
      }
        
        else if (input === 'All') {
          if (this.LanguageGlobal === 'Deutsch' ) {
            grammarPrompt = this.Selection + '\n\nKorrigiere die Grammatik im Text oben: '
          }
          else if (this.LanguageGlobal === 'English') {
            grammarPrompt = this.Selection + '\n\nFix the grammar in the text above: '
          }
        }

        else if (input === 'Sentence Structure') {
          if (this.LanguageGlobal === 'Deutsch' ) {
            grammarPrompt = this.Selection + '\n\nKorrigiere die Satzstruktur im Text oben: '
          }
          else if (this.LanguageGlobal === 'English') {
            grammarPrompt = this.Selection + '\n\nFix the sentence structure in the text above: '
          }
        }

        else if (input === 'Word Order') {
          if (this.LanguageGlobal === 'Deutsch' ) {
            grammarPrompt = this.Selection + '\n\nKorrigiere die Wortreihenfolge im Text oben: '
          }
          else if (this.LanguageGlobal === 'English') {
            grammarPrompt = this.Selection + '\n\nFix the word order in the text above: '
          }
        }

        else if (input === 'Word Repetition') {
          if (this.LanguageGlobal === 'Deutsch' ) {
            grammarPrompt = this.Selection + '\n\nKorrigiere die Wortwiederholung im Text oben: '
          }
          else if (this.LanguageGlobal === 'English') {
            grammarPrompt = this.Selection + '\n\nFix the word repetition in the text above: '
          }
        }

        else if (input === 'Wordiness') {
          if (this.LanguageGlobal === 'Deutsch' ) {
            grammarPrompt = this.Selection + '\n\nKorrigiere die Wortfülle im Text oben: '
          }
          else if (this.LanguageGlobal === 'English') {
            grammarPrompt = this.Selection + '\n\nFix the wordiness in the text above: '
          }
        }

        else if (input === 'Passive Voice') {
          if (this.LanguageGlobal === 'Deutsch' ) {
            grammarPrompt = this.Selection + '\n\nKorrigiere die Passivform im Text oben: '
          }
          else if (this.LanguageGlobal === 'English') {
            grammarPrompt = this.Selection + '\n\nFix the passive voice in the text above: '
          }
        }

        else if (input === 'Active Voice') {
          if (this.LanguageGlobal === 'Deutsch' ) {
            grammarPrompt = this.Selection + '\n\nKorrigiere die Aktivform im Text oben: '
          }
          else if (this.LanguageGlobal === 'English') {
            grammarPrompt = this.Selection + '\n\nFix the active voice in the text above: '
          }
        }
        else if (input === 'Point of View') {
          if (this.LanguageGlobal === 'Deutsch' ) {
            grammarPrompt = this.Selection + '\n\nÄndere die Erzählperspektive: '
          }
          else if (this.LanguageGlobal === 'English') {
            grammarPrompt = this.Selection + '\n\nChange the point of view: '
          }
        }

      const completion = await Completion( grammarPrompt, "text-davinci-003", maxTokens,'', '' );        
      console.log('Grammar completion: ', completion.completion)
      // Exchange the current selection with the completion
      //this.editor.chain().focus().deleteSelection().insertContent('<span style="color: rgb(158, 158, 158)">' + completion.completion + '</span>').run()
      this.InsertRewrite(completion.completion)
      this.loading = false
      this.$emit('update:loading', this.loading)

    },
    async Autocomplete () {
      this.loading = true
      this.$emit('update:loading', this.loading)
      let TextBeforeCursor = this.editor.getText().slice(0, this.Cursorposition -3)
      let TextAfterCursor = this.editor.getText().slice(this.Cursorposition -2)


      while (TextBeforeCursor.slice(-1) === '.') {
        TextBeforeCursor = TextBeforeCursor.slice(0, -1)
      }

      let prefixPrompt = TextBeforeCursor.slice(-1000)
      let suffixPrompt = TextAfterCursor.slice(0, 100)

      console.log('prefixPrompt: ', prefixPrompt)
      console.log('suffixPrompt: ', suffixPrompt)

      //let autoCompletePrompt ='The following is an autocomplete program for a creative writer. The goal is to provide helpful and inspiring suggestion without making stuff up.\nContinue the text with a smart and concise suggestion:\n\n' + prefixPrompt  

      let autoCompletePrompt
      if (this.LanguageGlobal === 'Deutsch' ) {
        autoCompletePrompt = '\n\nFühre den Text mit einem klugen und präzisen Vorschlag fort, ohne die Richtung zu stark zu verändern.\n\n' + prefixPrompt
      }
      else if (this.LanguageGlobal === 'English') {
        autoCompletePrompt = 'Continue this sentence with a smart and concise suggestion in the same style without changing the direction.\n\n' + prefixPrompt
        }
      
      else {
        autoCompletePrompt = 'Continue this sentence with a smart and concise suggestion in the same style without changing the direction.\n\n' + prefixPrompt
        }

      const completion = await Completion(autoCompletePrompt, "text-davinci-003", 150,'.', suffixPrompt );   
      console.log('TipTap Autocompletion1: ', completion.completion)
      this.lastAutocompletePrompt = autoCompletePrompt
      this.lastAutocompleteSuffix = suffixPrompt
      this.lastAutocomplete = completion.completion
      this.lastAutocompleteModel = 'text-davinci-003'

      
      if (completion.completion.length > 3) {
        let autocompletion = completion.completion + ' '
        this.InsertAutocompletion(autocompletion)
      }

      else if (completion.completion.length < 4)  {
        console.log('No Autocompletion, try second time')

        const  secondCompletion = await Completion(autoCompletePrompt, "text-davinci-002", 150,'.', '' );
        console.log('secondCompletion2: ', secondCompletion.completion)
        this.lastAutocompletePrompt = autoCompletePrompt
        this.lastAutocompleteSuffix = suffixPrompt
        this.lastAutocomplete = completion.completion
        this.lastAutocompleteModel = 'text-davinci-002'


        if (secondCompletion.completion.length > 3) {
        let autocompletion = secondCompletion.completion + ' '
        this.InsertAutocompletion(autocompletion)
        }

        else if (secondCompletion.completion.length < 3)  {
          console.log('No Autocompletion, try third time')

          const thirdCompletion = await Completion(autoCompletePrompt.slice(-200), "text-curie-001", 50,);
          console.log('thirdCompletion: ', thirdCompletion.completion)
          this.lastAutocompletePrompt = autoCompletePrompt
          this.lastAutocompleteSuffix = suffixPrompt
          this.lastAutocomplete = completion.completion
          this.lastAutocompleteModel = 'text-curie-001'

          if (thirdCompletion.completion.length > 3) {
          let autocompletion = thirdCompletion.completion + ' '
          this.InsertAutocompletion(autocompletion)
        }
      }

      }

      this.loading = false
      this.$emit('update:loading', this.loading)
      
    },
    async setHeadingPlaceholder() {
      if (this.editor.getText() === '') {
      console.log('Editor is empty')
      const completion = await Completion( '\n\nWrite a short prompt in the style of oblique strategies: ', "text-davinci-003", 100,'.', '' );        
      console.log('setHeadingPlaceholder: ', completion.completion)
      // Set the HeadingPlaceholder to a completion 
      // const completion = await Completion('\n\nWrite a short prompt in the style of oblique strategies: ', "text-davinci-003", 30,'.', '' )    
      this.HeadingPlaceholder = completion.completion
    }
    },

    async detectLanguage() {
      
      if (this.LanguageDetected === false) {

      let prompt = this.editor.getText().slice(0, 100) + "\n\nIf the text above is in german answer German and if it is english answer English\n\n" 
      console.log('detectLanguage prompt: ', prompt)
      const response = await Completion(prompt, "text-davinci-003", 15, ' ');
      console.log('detectLanguage response: ', response.completion)

      if (response.completion == "German" || response.completion == "Deutsch" || response.completion == "german" || response.completion == "DE" || response.completion == "De" || response.completion == "de") {
        this.updateLanguageGlobal("Deutsch")   
        console.log("Language set to: " + this.LanguageGlobal)  
        this.LanguageDetected = true 
      }    
      else if (response.completion == "English" || response.completion == "english" || response.completion == "En" || response.completion == "en" || response.completion == "Englisch" || response.completion == "English.") {
        this.updateLanguageGlobal("English")   
        console.log("Language set to: " + this.LanguageGlobal)
        this.LanguageDetected = true 
      }         
      }
      else {
        console.log('Language already detected')
      }
    },

    // A function that uses debounce to trigger the Autocomplete function after 3s
    debounceAutocomplete: debounce(function() {

      // Only if the last typed character is a space, trigger the Autocomplete function
      console.log('lastTypedCharacter in debounce: ', this.lastTypedCharacter)
      if (this.lastTypedCharacter == ' ') {
        this.Autocomplete()
      }
      else {
        console.log('No Autocomplete, last character is not a space')
      }


      //this.Autocomplete()

    }, 
    // A random number between 5000 and 9000
    Math.floor(Math.random() * (6000 - 1000 + 1) + 1000)
    
    ),

    handleTab() {
      if (true) {
        this.AcceptAutocompletion()
      }
      if (this.PendingRewrite) {
        this.AcceptRewrite()
      }
    },

    handleSpace() {
      if (this.typedCharacters > 1) {
        this.debounceAutocomplete()
      }
    },

    handleArrow() {
      console.log('handleArrow')
      if (this.PendingAutocompletion) {
        //this.Autocompletion()
      }
    },

    handleEnter() {
      if (true) {
        this.AcceptAutocompletion()
        // Remove the last typed enter 
        //this.editor.chain().focus().deleteRange({ from: this.Cursorposition - 2, to: this.Cursorposition - 1 }).run()
      }
      if (false) {
        this.AcceptRewrite()
      }
    },

    TiptapInput(event) {

      this.lastTypedCharacter = event.data

      if (this.lastTypedCharacter === '.') {
        this.typedDots += 1
      }
      else {
        this.typedDots = 0
      }

      ///console.log('typedDots: ', this.typedDots)

      if (this.typedDots === 3 || this.lastTypedCharacter === '…') {

        // Select the last typed dots
        this.editor.commands.setTextSelection({ from: this.Cursorposition - 2, to: this.Cursorposition +1 })
        this.editor.commands.deleteSelection()

        // Delete the last typed dots
        //this.editor.chain().focus().deleteRange({ from: this.Cursorposition - 2, to: this.Cursorposition +1}).run()

        this.editor.commands.insertContent({
        type: 'AutocompleteNode',
        attrs: {
          class: 'AutocompletionMarker',
        },

        content: [
          {
            type: 'text',
            text: '…',
          },
        ],
      }
      )
        
        
        this.Autocomplete()   
        this.typedDots = 0
        
      }

      else {
      console.log('deleteAutocompletion')
      this.DeleteAutocompletion()
      }
      
    },

    InsertRewrite(value) {

      // Find out if font-family if the string 'font-family: SuisseMono' appears in the the content 
      let editorHTML = this.editor.getHTML()
      if (editorHTML.includes('font-family: SuisseMono' || 'font-family: font-mono')) {
        this.styledRewrite = '<span style="font-family: SuisseMono;">'+ value + '</span>'
      } 
      else if (editorHTML.includes('font-family: SuisseIntl' || 'font-family: font-sans')) {
        this.styledRewrite = '<span style="font-family: SuisseIntl;">'+ value + '</span>'
      }
      else if (editorHTML.includes('font-family: SuisseWorks' || 'font-family: font-serif')) {
        this.styledRewrite = '<span style=" font-family: SuisseWorks;">'+ value + '</span>'
      }
      else {
        this.styledRewrite = value }

      this.ContentBeforeInsertion = this.editor.getHTML()
      this.CursorpositionBeforeInsertion = this.Cursorposition
      this.editor.chain().focus().deleteSelection().insertContent(this.styledRewrite).run()

      this.PendingRewrite = true

      },

      AcceptRewrite() { 
      // If styledAutoCompletion is in editor.getHTML() replace it with this.autoCompletion
      if (this.PendingRewrite) {
        // Remove any color from editor
        let Cursor = this.Cursorposition
        this.editor.chain().focus().selectAll().unsetColor().run()
        this.editor.commands.setTextSelection(Cursor)
        

        this.PendingRewrite = false
        this.styledRewrite = null
        this.typedCharacters = 0
      }
      },

      DeleteRewrite() {
      if (this.PendingRewrite) {
        this.editor.commands.setContent(this.ContentBeforeInsertion, true)
        this.editor.chain().focus().setTextSelection(this.CursorpositionBeforeInsertion).run()
        this.ContentBeforeInsertion = null
        this.CursorpositionBeforeInsertion = null
        this.styledRewrite = null
        
        // Remove any color from editor
        let Cursor = this.Cursorposition
        this.editor.chain().focus().selectAll().unsetColor().run()
        this.editor.commands.setTextSelection(Cursor)
        
        this.PendingRewrite = false
      }
    },

    InsertAutocompletion(value) {

      let editorHTML = this.editor.getHTML()
      let editorText = this.editor.getText()

      this.editor.commands.setTextSelection({ from: this.Cursorposition - 2, to: this.Cursorposition +1 })
      this.editor.commands.deleteSelection()

      this.ContentBeforeInsertion = this.editor.getHTML()
      this.CursorpositionBeforeInsertion = this.Cursorposition

      this.editor.commands.insertContent({
        type: 'AutocompleteNode',
        attrs: {
          class: 'AutocompletionMarker',
        },

        content: [
          {
            type: 'text',
            text: value,
          },
        ],
      }
      )

    this.PendingAutocompletion = true

    },

    DeleteAutocompletion() {
      if (this.PendingAutocompletion) {

      // Check if there is a AutocompleteNode in the editor
      if (this.editor.getHTML().includes('AutocompletionMarker')) {
        
      this.editor.commands.deleteNode('AutocompleteNode')

      }
 
      this.PendingAutocompletion = false

      }
    },

    AcceptAutocompletion() { 

      if (this.PendingAutocompletion == true) {

        let CursorBefore = this.Cursorposition
        this.editor.commands.selectNodeBackward()
        
        this.editor.commands.updateAttributes('AutocompleteNode', {
          class: null,
        })

        this.editor.commands.updateAttributes('AutocompleteNode', {
          class: 'AcceptedAutocompletion',
        })

        this.editor.commands.setTextSelection(CursorBefore)
        this.PendingAutocompletion = false

        // Delete Enter

        this.editor.chain().focus().deleteRange({ from: this.Cursorposition , to: this.Cursorposition +2 }).run()
        this.saveCompletion()

        }

        this.PendingAutocompletion = false
        this.styledAutoCompletion = null
        this.typedCharacters = 0
    },

    async saveCompletion() {

      if (this.lastAutocompleteSuffix === null) {
        this.lastAutocompleteSuffix = ''
      }
      if (this.user.uid === 'pCvXW0xnmkM2Wej7aP0kSpZkSMf2') {
        return
      }

      else {

      try {
        const colRef = collection(db, 'completions')
        await addDoc(colRef, 
          {
            prompt: this.lastAutocompletePrompt,
            suffixPrompt: this.lastAutocompleteSuffix,
            completion: this.lastAutocomplete,
            model: this.lastAutocompleteModel,
            user: this.user.uid,
            createdAt: serverTimestamp()

          })
         
        }
        catch(err) {
          console.log(err.message)
        }
      }

    }
    
  }, 

  watch: {
    modelValue(value) {

      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }
      this.editor.commands.setContent(value, false)

      if (value.length > 60) {
        this.detectLanguage()
      }
      
    },
    undo(value) {
      if (this.PendingAutocompletion) {
        this.PendingAutocompletion = false
      }
      this.editor.chain().focus().undo().run()
    
    },

    redo(value) {
      if (this.PendingAutocompletion) {
        this.PendingAutocompletion = false
      }
      this.editor.chain().focus().redo().run()},
    
    changeFont(value) {this.setFontFamily()},
    },
   
    mounted() {
      this.HeadingPlaceholder = this.obliquePrompts[Math.floor(Math.random() * this.obliquePrompts.length)]

      this.editor = new Editor({

      extensions: [
      CustomDocument,
      AutocompleteNode,
      StarterKit.configure({
        document: false,
        history: true,
      }),
      Image,
      TextStyle,
      FontFamily,
      Color,
      Highlight.configure({
        HTMLAttributes: {
          class: 'HighlightMarker',
        },
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === 'heading') {
            // Return a random placeholder from obliquePrompts
            return this.HeadingPlaceholder
          }
          else if (node.type.name === 'paragraph') {
            return 'Type three dots ... to get suggestions and Enter to accept.'
          }
        },
      })
    ],
  //   parseOptions: {
  //   preserveWhitespace: 'full',
  // },
      editorProps: {
        attributes: {
           class: 'hr text-left prose-lg prose md:prose-lg m-5 mx-7 focus:outline-none prose-headings:text-neutral-800 prose-headings:dark:text-zinc-300',
        }
      },
      autofocus: true,
      injectCSS: false,
      BulletList: true,
      Heading: true,
      OrderedList: true,
      onSelectionUpdate: () => {
        
        const { from, to } = this.editor.view.state.selection
        this.Selection = this.editor.getText().substring(from -1, to -1)
        //console.log('this.Selection', this.Selection)
        this.$emit('update:cursorPosition', this.Cursorposition)
      },
      onUpdate: () => {

        this.$emit('update:modelValue', this.editor.getHTML())

        // Get the first node in the editor
        //const firstNode = this.editor.view.state.doc.firstChild
        //console.log('firstNode', firstNode)

      },
      onTransaction: ({ state }) => {
        this.Cursorposition = this.editor.view.state.selection.anchor -1
        //this.Cursorposition = from -1
        //this.Cursorposition = state.selection.anchor 
        //console.log(this.editor.state.selection.anchor)
      },
    })
    
    // Scroll at last position
    //this.editor.commands.focus('end')
    this.editor.commands.setTextSelection(this.editor.getText().length)
    this.editor.commands.scrollIntoView()



  },
  
  beforeUnmount() {
    
    this.editor.destroy()
  },
}
</script>

<style>


.ProseMirror p {
  margin: 1em 0;
}

 .img {
    max-width: 100%;
    height: 200px;
 }


.ProseMirror .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #b6b6b6;
  pointer-events: none;
  height: 0;
}


.lds-ring {
  display: inline-block;
  position: absolute;
  left: 55%;
  top: 20%;
  width: 60px;
  height: 60px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  border: 8px solid #1025e035;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1025e035 transparent transparent transparent;

}
.lds-ring div:nth-child(1) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.2s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.1s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 30px auto;
  border: 3px solid transparent;
  border-radius: 50%;
  border-top-color: #1025e035;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to { -webkit-transform: rotateZ(360deg);  }
}


.dots-6 {
  width: 40px;
  aspect-ratio: 1.154;
  --_g: no-repeat radial-gradient(farthest-side,#DBFF00 90%,#0000);
  background: 
    var(--_g) 50%  0,
    var(--_g) 0    100%,
    var(--_g) 100% 100%;
  background-size: 35% calc(35%*1.154);
  animation: d6 1s infinite;
}

@keyframes d6{ 
    50%,100% {background-position: 100% 100%,50% 0,0 100%} 
}


.tooltip-button {
  position: relative;
}

.tooltip-text {
  visibility: hidden;
  width: 100px;
  background-color: rgb(225, 225, 225);
  color: rgb(53, 53, 53);
  text-align: center;
  padding: 5px 0;
  border-radius: 500px;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 50%;
  margin-left: -80%;
  font-size: smaller;
  padding: 5px 10px;

}

.tooltip-button:hover .tooltip-text {
  visibility: visible;
}

.AutocompletionMarker {
  background-color: rgba(211, 211, 211, 0);
  color: #b6b6b6;
}


</style>