import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import {auth} from './firebase/config'
import './registerServiceWorker'


if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
      console.log('Service worker registered:', registration);
    }, function(error) {
      console.log('Service worker registration failed:', error);
    });
  });
}

let app

function preventDefault(e){
    e.preventDefault();
}

function disableScroll(){
    document.body.addEventListener('touchmove', preventDefault, { passive: false });
}

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .use(router)
      .mount('#app')
  }
})




// let app

// function preventDefault(e){
//     e.preventDefault();
// }

// function disableScroll(){
//     document.body.addEventListener('touchmove', preventDefault, { passive: false });
// }



// auth.onAuthStateChanged(() => {
//     if (!app) {


//     app = createApp(App)
//     .use(router)
//     .mount('#app')

//     }
// })





//     //Prmpt the user to install the app
//     if (window.matchMedia('(display-mode: standalone)').matches) {
//         console.log('display-mode is standalone');
//     } else {
//         console.log('display-mode is not standalone');
//         disableScroll()
//         document.getElementById('install-prompt').style.display = 'block'
//     }