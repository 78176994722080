
const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
  apiKey: process.env.VUE_APP_OPENAI_API_KEY
});

const openai = new OpenAIApi(configuration);

const GenerateImage = async (input, n) => {

  const response = await openai.createImage({
    prompt: input,
    n: n,
    size: "1024x1024",
  });

  let ImageURLs = response.data.data[0].url

   
  return { ImageURLs };
    
    }
  
  export default GenerateImage