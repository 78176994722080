import { ref } from 'vue'

const selectedGlobalProject = ref(null)

export function useSelectedProject() {

  function updateSelectedGlobalProject(newState) {

    selectedGlobalProject.value = newState 

    //  return selectedGlobalProject.value
  }

  return {
    selectedGlobalProject,
    updateSelectedGlobalProject
  }
}
