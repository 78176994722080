<template>

  <!-- <div class="absolute z-30 w-full flex h-screen  justify-center backdrop-blur-md">
    <div  class="settings bg-opacity-95 transition-height-width flex flex-row flex-nowrap rounded-3xl  w-3/6 h-screen75  m-auto">
    
      <div class="ml-10 grid grid-rows-6 grid-flow-col gap-4">
      <h1 class="h1 ps-5 pt-5 m-5">Settings</h1>
      <div><div class="p p-1 ml-5 pill">Upgrade</div></div>

      <div><div @click="handleLogout" class="p p-1 ml-5 pill">Logout</div>  </div>
      <div class="float-right"><div class="p p-1 ml-5 mt-10 mr-0 flex justify-center items-center"><div class="h2"> Credits Left &ensp;</div> {{ CreditsLeft }}</div></div>

    </div>

    </div>
 </div> -->

  <!-- <div class="absolute z-30 bottom-0 left-0 m-3">
    <div  class="settings  w-48 h-56 transition-height-width flex flex-row flex-nowrap rounded-3xl">
    
      <div class="flex flex-col">
      <h1 class="h2 m-3">Settings</h1>
      <div><div class="p m-3 pill">Upgrade</div></div>

      <div><div @click="handleLogout" class="p m-3 pill">Logout</div>  </div>
      <div class="float-right m-3"><div class="p flex justify-center items-center"><div class="h3"> Words Left &ensp;</div> {{ CreditsLeft }}</div></div>

    </div>

    </div>
 </div> -->

   <div class="w-full h-full flex flex-row flex-nowrap  z-90 p-3  pb-20">
    
      <div class="ml-4 mt-2 flex text-lg w-full h-full flex-col gap-1 pt-4 content-center text-neutral-800 dark:text-zinc-400">

        <div class="flex items-center m-1">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 48 48"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"><circle cx="24" cy="24" r="20"/><path d="M24 4a20 20 0 0 1 14.58 6.31L24 24V4Z"/></g>
            </svg>
          <div class="p m-1 flex justify-start">
            <div class="h3"> Credits Left &ensp;

            </div> {{ CreditsLeft }}
          </div>
        </div>

        <div @click="changeLanguage()" class="cursor-pointer flex items-center m-1">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.8" class="w-5 h-5 svgStroke">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
          </svg>
        <!-- <div class="p m-1 flex justify-start">Language</div> -->
        <div  class=" p m-1 flex justify-start">
            <div class="h3"> Language &ensp;
              
            </div> {{ LanguageGlobal }}
          </div>
        </div>

        <div class="flex items-center m-1"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-6 h-6 svgStroke">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
        </svg>
        <div class="p m-1">Upgrade</div></div>

        <div class="flex items-center m-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>

        <div class="p m-1">Account</div>
      </div>

      <div @click="showModal = !showModal" class="flex items-center m-1 cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
          <path fill-rule="evenodd" d="M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z" clip-rule="evenodd" />
        </svg>
        <div class="p m-1">Connect</div>
      </div>

      <div class="flex items-center m-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12 19c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m0-18c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2M6 16c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m-3-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m3-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m12 12c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m3-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m-3-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2Z"/></svg>
        <div class="p m-1">AI Preferences</div>
      </div>


        <div @click="toggleDarkmode" class="flex items-center m-1 cursor-pointer">
          <div  class="z-50  opacity-70 hover:opacity-100">
          <svg v-if="!darkOn" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"  class="w-5 h-5 svgStroke ">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
          </svg>

          <svg v-if="darkOn" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" class="w-5 h-5 svgStroke">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
          </svg>    
        </div>
            <div v-if="!darkOn" class="p m-1 ">Dark mode</div> 
            <div v-if="darkOn" class="p m-1 ">Light mode</div> 
        </div>

        <div @click="handleLogout" class="flex items-center m-1 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  class="w-6 h-6 svgStroke">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
            </svg>
            <div  class="p m-1 ">Logout</div> 
        </div>
        
        
    </div>
 </div>

 <AppsModal v-if="showModal" @closed="showModal = false" />

    
</template>

<script>

import { useRouter } from 'vue-router'
import useLogout from '../composables/useLogout'
import getUser from '../composables/getUser'


import { computed, onMounted, ref, watch } from 'vue'
import { collection, addDoc, serverTimestamp, deleteDoc, updateDoc, doc, getDoc} from '@firebase/firestore'
import { db } from '@/firebase/config'
import { LanguageGlobal, useLanguageGlobal} from '../composables/useLanguageGlobal'
import AppsModal from './AppsModal.vue'

export default {
    emits: ["languageChanged"],
    setup(props, context) {
        const { logout, error } = useLogout();
        const { user } = getUser();
        const router = useRouter();
        const CreditsLeft = ref(100000);
        const { LanguageGlobal, updateLanguageGlobal } = useLanguageGlobal();
        const darkOn = ref(false);
        const showModal = ref(false);
        const changeLanguage = () => {
            if (LanguageGlobal.value == "English") {
                updateLanguageGlobal("Deutsch");
            }
            else {
                updateLanguageGlobal("English");
            }
        };
        onMounted(async () => {
            if (user.value) {
                const docSnap = await getDoc(doc(db, "users", user.value.uid));
                CreditsLeft.value = docSnap.data().usageCredits / 10;
                // Make CredisLeft even (Copilot)
                if (CreditsLeft.value % 2 != 0) {
                    CreditsLeft.value = Math.floor(CreditsLeft.value);
                }
            }
        });
        // const CreditsLeft = computed(async() => {
        //     if(user.value) {
        //         const docSnap = await getDoc(doc(db, "users", user.value.uid));
        //         console.log(docSnap.data().usageCredits)
        //         return docSnap.data().usageCredits;
        //     }
        // })
        const handleLogout = async () => {
            await logout();
            if (!error.value) {
                console.log("user logged out");
            }
        };
        watch(user, () => {
            if (!user.value) {
                router.push({ name: "Login" });
            }
        });
        const toggleDarkmode = () => {
            darkOn.value = !darkOn.value;
            document.body.classList.toggle("dark");
            // Save the preference to the users local storage
            localStorage.setItem("darkmode", darkOn.value);
        };
        return {
            handleLogout,
            CreditsLeft,
            changeLanguage,
            LanguageGlobal,
            toggleDarkmode,
            darkOn,
            showModal
        };
    },
    components: { AppsModal }
}
</script>

<style>

</style>