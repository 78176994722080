<template>
<div class="">
    <!-- <div v-if="showSettings" @click="$emit('toggleSettings')"
    class="btn z-50 fixed bottom-1/2 left-2 ">

        <div class="btn bg-neutral-200 dark:bg-zinc-700 opacity-70 hover:opacity-100 rounded-full"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="grey" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
            </svg>
        </div>

    </div>  -->


    <!-- <div v-if="showSettings" @click="$emit('toggleSettings'), toggleSettings = !toggleSettings"
    class="btn z-50 fixed bottom-1/2 left-0 ">

    <div class="bg-transparent cursor-pointer rounded-full w-10 h-10 btn transition-rotation duration-300 ease opacity-40 hover:opacity-60 hover:bg-neutral-200 dark:bg-zinc-600 " :class="{'rotate-90 m-1': toggleSettings, '-rotate-90 mt-12': !toggleSettings}">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="grey" class="">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        </div>
    </div>  -->

    <div v-if="showSettings" @click="$emit('toggleSettings'), toggleSettings = !toggleSettings"
    class="btn z-50 fixed bottom-0 left-0 ">

    <div class="bg-transparent cursor-pointer rounded-full w-10 h-10 btn transition-rotation duration-300 ease opacity-80  hover:bg-neutral-100 dark:bg-zinc-600 ">

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="grey" class="w-8 h-8">
             <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
            </svg>

        </div>
    </div> 



    <!-- <div @click="toggleDarkmode" class="btn z-50 bg-neutral-200 dark:bg-zinc-700 rounded-full fixed bottom-2 right-4 m-2 p-2 opacity-70 hover:opacity-100">
        <svg v-if="!darkOn" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  class="w-5 h-5 svgStroke ">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
        </svg>
        <svg v-if="darkOn" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-5 h-5 svgStroke">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
        </svg>    
    </div> -->

</div>
</template>

<script>

import { useRouter, useRoute } from 'vue-router'
import { computed, ref, watch } from 'vue'

export default {
    name: 'Footer',
    emits: ['toggleSettings'],
    setup () {
        const router = useRouter()
        const darkOn = ref(false)
        const toggleSettings = ref(false)


        const showSettings = computed(() => {
            if (useRoute().name == 'Editor') {
                return false
            } else if (useRoute().name == 'Login') {
                return false
            }

            else {
                return true
            }
        })
        
        const toggleDarkmode = () => {
            darkOn.value = !darkOn.value
            document.body.classList.toggle('dark')

            // Save the preference to the users local storage
            localStorage.setItem('darkmode', darkOn.value)
            
        }
        return {
            toggleDarkmode,
            darkOn,
            showSettings,
            toggleSettings
        }
    }

}
</script>

<style>

</style>