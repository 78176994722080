

const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
  apiKey: process.env.VUE_APP_OPENAI_API_KEY
});

const openai = new OpenAIApi(configuration);

import getUser from '../composables/getUser'
import {  collection, addDoc, serverTimestamp, getDoc, doc, updateDoc, increment } from "firebase/firestore";
import { db } from '@/firebase/config'

const Completion = async (input, model, length, stopCharacter, suffixPrompt, temperature, frequency, presence, top_p ) => {

  // If temperature is not set, set it to 0.9
  if (temperature == null) {
    temperature = 0.9
  }
  // If frequency is not set, set it to 0.5
  if (frequency == null) {
    frequency = 0.5
  }
  // If Presence is not set, set it to 0.6
  if (presence == null) {
    presence = 0.6
  }
  // If top_p is not set, set it to 1
  if (top_p == null) {
    top_p = 1
  }


  // console.log('Input: ' + input)
  // console.log('Model: ' + model)
  // console.log('Length: ' + length)
  // console.log('Stop: ' + stopCharacter)
  // console.log('Suffix: ' + suffixPrompt)
  // console.log('Temperature: ' + temperature)
  // console.log('Frequency: ' + frequency)
  // console.log('Presence: ' + presence)
  // console.log('Top_p: ' + top_p)


  const response = await openai.createCompletion({
  model: model,
  prompt: input,
  temperature: temperature,
  max_tokens: length,
  top_p: top_p,
  frequency_penalty: frequency,
  presence_penalty: presence,
  stop: stopCharacter,
  n: 1,
  best_of: 1,
  suffix: suffixPrompt
  });
  //console.log('JSON response: ' + JSON.stringify(response.data.choices[0], null, 4));
  //console.log('Completion: ' + response.data.choices[0])

  let completion = response.data.choices[0].text
  let finish_reason = response.data.choices[0].finish_reason

  // If finish_reason is 'stop', add '. ' to the end of the completion
  if (finish_reason == 'stop') {
    //completion = completion + stopCharacter
  }

  while (completion[0] == '\n' || completion[0] == ' ' ) {
    completion = completion.slice(1);
}

  //console.log(completion)
  let usage = response.data.usage.total_tokens
  console.log('Usage: ' + usage)

  //Get User and subtract credits

  const {user} = getUser()


  if (user.value) {

  const docSnap = await getDoc(doc(db, "users", user.value.uid));
 
  
  if (docSnap.exists()) {
    const usageCredits = docSnap.data().usageCredits
    console.log('new usageCredits: ', (usageCredits - usage))
    
  } else {
    console.log("No such document");
  }

  try {
    const docRef = doc(db, "users", user.value.uid);
    await updateDoc(docRef, {
      usageCount: increment(usage),
      usageCredits: increment(-usage)

    })
  } catch (err) {
    console.log(err.message)
  }
}

  // if (suffixPrompt == null) {
  //   suffixPrompt = ''
  // }

  // let completionRef = null

  // try {
  //   const colRef = collection(db, 'completions')
  //   completionRef = await addDoc(colRef, 
  //     {
  //       prompt: input,
  //       suffixPrompt: suffixPrompt,
  //       completion: completion,
  //       model: model,
  //       temperature: temperature,
  //       frequency: frequency,
  //       presence: presence,
  //       top_p: top_p,
  //       usage: usage,
  //       uid: user.value.uid,
  //       createdAt: serverTimestamp()

  //     })
  //     completionRef = completionRef.id
  //   }
  //   catch(err) {
  //     console.log(err.message)
  //   }


   
  return  {completion} ;
    
    }
  
  export default Completion



