<template>

<div>
    <div  class="flex  h-screen justify-between flex-row">

        <!-- <div  @click.prevent="showChat = !showChat" class="fixed top-6 right-6 cursor-pointer rounded-full bg-neonyellow  dark:bg-gray-300 dark:border dark:border-blue-800 blur-[1px] hover:blur-0 w-5 h-5 opacity-80 hover:opacity-100 transition duration-200 ease scale-100 hover:scale-110"></div> -->


            <div class="module bg-neutral-200 m-2  transition-height-width flex flex-row flex-nowrap " 
                    :class="{Settings0: toggleSettings == false, Settings1: toggleSettings == true}">
             
             <Transition name="settings">                    
                <div v-if="toggleSettings" class="w-full">
                    <Settings/>
                </div>
            </Transition>
            </div>


        <div class=" w-full h-full flex flex-col items-center">

        <div  class="transition-height-width  rounded-t-xl bg-opacity-80 duration-200 ease" 
                                        :class="{'h-0': !showChat, 'w-full md:w-10/12 xl:w-7/12 h-screen80': showChat}">
            <Transition name="slide" mode="out-in">
                <div v-if="showChat" class="h-full">              
                    <HomeChat :chatInput="chatInput" :sendChat="sendChat"  />
                </div>    
            </Transition>
        </div>

        <Transition name="heading">
            <div v-if="noLocalDocuments && !showChat" class="absolute flex justify-center mt-36">
                <!-- <div  @click.prevent="showChat = !showChat" class="m-auto mr-4 cursor-pointer rounded-full bg-neonyellow  dark:bg-gray-300 dark:border dark:border-blue-800 blur-[1px] hover:blur-0 w-5 h-5 opacity-80 hover:opacity-100 transition duration-200 ease scale-100 hover:scale-110"></div> -->

                <div class="flex-col justify-center w-full antialiased text-center items-center">
                    <div class="text-neutral-125 leading-12 dark:text-zinc-600 font-light text-4xl sm:text-6xl md:text-7xl"> {{ displayName }}</div> 
                    <!-- <div class="text-neutral-500 mt-6 font-light text-2xl md:text-4xl">How can I help you today?</div>  -->
                </div>
            </div>
        </Transition>


        <div class="flex shrink-0 w-full p-4 justify-center items-center flex-col  m-auto transition-height-width duration-300 ease rounded-3xl bg-neutral-200 bg-opacity-0 z-90">
        
        <div class="flex gap-2 w-full m-auto justify-center ">


            <div v-if="!showChat" class="transition-height-width transition-position duration-300 h-10 ease mb-6 relative bg-neutral-200 bg-opacity-90 dark:bg-opacity-70 dark:bg-zinc-700 shadow-2xl flex flex-row flex-nowrap rounded-full justify-between"
                    :class="{'w-7/12': showSearch && showAllDocuments, 'w-4/12': showSearch && !showAllDocuments, 'w-10': !showSearch}">


                <input v-if="showSearch" v-model="query" class="float-left bg-transparent text-lg ml-4 w-11/12" placeholder="Search your documents" type="text">

                <div class="btn w-10 h-10 float-right" @click.prevent="showSearch = !showSearch">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="#B9B9B9" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </div>
            </div>


            <div v-if="showProjects" class="transition-height-width transition-position duration-300 h-10 ease mb-6 relative bg-neutral-200 bg-opacity-90 dark:bg-opacity-70 dark:bg-zinc-700 shadow-3xl flex flex-row flex-nowrap rounded-full justify-start"
                :class="{ 'w-10': showSearch, 'xl:w-5/12 md:w-3/5 w-full ': !showSearch && !showChat, 'w-full md:w-10/12 xl:w-7/12': showChat}">

                <!-- <input v-if="showSearch" v-model="query" class="float-left bg-transparent text-lg ml-4 w-11/12" placeholder="Search your documents" type="text"> -->

                <!-- <div class="btn w-10 h-10 float-right" @click.prevent="showSearch = !showSearch">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="#B9B9B9" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </div> -->

                <div  @click.prevent="showChat = !showChat" class="m-auto ml-3 mr-3 cursor-pointer rounded-full bg-neonyellow  dark:bg-gray-300 dark:border dark:border-blue-800 blur-[1px] hover:blur-0 w-5 h-5 opacity-80 hover:opacity-100 transition duration-200 ease scale-100 hover:scale-110"></div>




                <input v-if="!showSearch" @keydown.enter.prevent="send()" v-model="chatInput" class=" float-left prose prose-lg bg-transparent w-11/12" placeholder="Start a conversation" type="text">
                
                <div v-if="chatInput && !showSearch" @click.prevent="send()" class="absolute btn right-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#b9b9b9" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                    </svg>
                </div>

                <div v-if="!showChat && showSearch" @click.prevent="showSearch = false" class="m-auto btn ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#b9b9b9" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                    </svg>
                </div>

            </div>


            <div v-if="!showChat" class="transition-height-width transition-position duration-300 h-10 ease mb-6 relative bg-neutral-200 bg-opacity-90 dark:bg-opacity-70 dark:bg-zinc-700 shadow-2xl flex flex-row flex-nowrap rounded-full justify-between">
                    <div class="btn w-10 h-10 " @click.prevent="newProject = !newProject">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.2" stroke="#B9B9B9" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
            </div>
        </div>

            <div  class="transition-height-width ml-4 rounded-t-xl w-full overflow-scroll lg:w-8/12 bg-opacity-80 duration-200 ease" 
                                        :class="{'h-0': query == '', ' h-screen80': query != '' || showAllDocuments}">
                <Transition name="projects">
                    <div v-if="showProjects && !showChat">              
                        <Projects @noLocalDocuments="(n) => noLocalDocuments = n" :showAllDocuments="showAllDocuments" :query="query" :ProjectsLayout="ProjectsLayout"  :newProject="newProject" />                
                    </div>    
                   <!-- <div class="transition duration-700 ease visible absolute bottom-0 w-full h-24 bg-gradient-to-b from-neutral-500/0 via-[#E1DFDD] to-[#E1DFDD] dark:invisible "></div> -->
                </Transition>
            </div>

            <!-- <div v-if="!showChat" class="flex w-full justify-center  transition-rotation duration-300 ease ">
                <div @click="showAllDocuments = !showAllDocuments, showSearch = !showSearch" class="bg-transparent cursor-pointer rounded-full w-10 h-10 btn transition-rotation duration-300 ease opacity-40 hover:opacity-60 hover:bg-neutral-200 dark:bg-zinc-600 " :class="{'-rotate-180 m-1': showAllDocuments, 'mt-12': !showAllDocuments}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="grey" class="">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                </div>
            </div> -->

        </div>
    </div>
        
    </div>

    <div v-if="!showChat" class="flex fixed bottom-2 w-full justify-center  transition-rotation duration-300 ease ">
        <div @click="showAllDocuments = !showAllDocuments" class="bg-transparent cursor-pointer rounded-full w-10 h-10 btn transition-rotation duration-300 ease opacity-40 hover:opacity-60 hover:bg-neutral-200 dark:bg-zinc-600 " :class="{'-rotate-180 m-1': showAllDocuments, 'mt-12': !showAllDocuments}">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="grey" class="">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        </div>
    </div>

    <div @click="showChat = false" v-if="showChat" class="flex fixed bottom-2 w-full justify-center  transition-rotation duration-300 ease ">
        <div class="bg-transparent cursor-pointer rounded-full w-10 h-10 btn transition-rotation duration-300 ease opacity-40 hover:opacity-60 hover:bg-neutral-200 dark:bg-zinc-600 rotate-180">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="grey" class="">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        </div>
    </div>

    <!-- <div @click="showChat = false, showProjects = true" v-if="showChat" class="fixed bottom-2 w-full m-auto h-10 rotate-180  flex flex-row flex-nowrap rounded-full justify-between">
        <div class="btn w-10 h-10 ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="#B9B9B9" class="">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        </div>
    </div> -->


<Footer @toggleSettings="toggleSettings = !toggleSettings"/>

</div>

</template>


<script setup>

    import Projects from '@/components/Projects.vue'
    import Footer from '@/components/Footer.vue'
    import Settings from '@/components/Settings.vue'
    import HomeChat from '@/components/HomeChat.vue'

    import { useRouter } from 'vue-router'
    import getUser from '../composables/getUser'
    import { useSelectedProject } from '../composables/useSelectedProject'
    import { doc, getDoc} from '@firebase/firestore'
    import { db } from '../firebase/config'
    import { computed, onUnmounted, onMounted, ref, watch } from 'vue'


    const router = useRouter()
    const {user} = getUser()
    const { selectedGlobalProject, updateSelectedGlobalProject } = useSelectedProject()
    const showProjects = ref(true)
    const showEditor = ref(false)
    const showChat = ref(false)
    const toggleSettings = ref(false)
    const newProject = ref(false)
    const ProjectsLayout = ref('Grid')
    const FullScreen = ref(false)
    const startSearch = ref(false)
    const query = ref('')
    const displayName = ref('')
    const noLocalDocuments = ref(null)
    const showSearch = ref(false)
    const chatInput = ref('')
    const sendChat = ref(false)
    const showAllDocuments = ref(false)

    

    onMounted(() => {
        checkNewUser()

        //window.addEventListener('scroll', handleScroll);
    })

    // onUnmounted( () => {
    //     window.removeEventListener('scroll', handleScroll)
    // } );


    // const handleScroll = ( event ) => {
    //     console.log('Scrollin!');
    // }
    


    const checkNewUser = async () => {
            const userRef = doc(db, 'users', user.value.uid);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                let firstName
                // If it's morning time, greet the user with good morning
                if (user.value.displayName.includes(' ')) {
                    firstName = user.value.displayName.split(' ')[0]
                    }
                else {
                    firstName = user.value.displayName
                    } 
                //console.log('Document data:', docSnap.data());
                if (docSnap.data().usageCount < 500) {
                    displayName.value = 'Welcome ' + firstName
                }
                else {
                    const hour = new Date().getHours()
                if (hour < 12) {
                    displayName.value =  'Good morning, ' + firstName
                }
                // If it's afternoon time, greet the user with good afternoon
                if (hour >= 12 && hour < 17) {
                    displayName.value =  'Good afternoon, ' + firstName
                }
                // If it's evening time, greet the user with good evening
                if (hour >= 17) {
                    displayName.value =  'Good evening, ' + firstName
                }
                    }
                } else {
                    // doc.data() will be undefined in this case
                    console.log('No such document!');
                    displayName.value =  'Hey there' 
                  
                    
                }
        }

    watch (selectedGlobalProject, (n) => {
        if (n) {
            //router.push('/Editor/' + n.id)
            console.log('n.id', n.id)
            router.push({ name: 'Editor', params: { id: n.id } })
            let id = n.id
            //router.push({ name: 'Editor', params: { id } })
            //showEditor.value = true
            showProjects.value = false
        }
        else if (n == null) {
            showEditor.value = false
            showProjects.value = true
        }
    })

    const toggleFullScreen = () => {
        FullScreen.value = !FullScreen.value
    }

    // If the user value is ever null, redirect to welcome screen

    watch(user, () => {
      if (!user.value) {
        router.push({ name: 'Landing' })
      }
    })
     

    const closeEditor = () => {
        //updateSelectedGlobalProject(null) 
        }

    const toggleLayout = () => {
        if (ProjectsLayout.value == 'Grid') {
            ProjectsLayout.value = 'List'
        }
        else if (ProjectsLayout.value == 'List') {
            ProjectsLayout.value = 'Grid'
        }
    }

    const send = () => {
        showChat.value = true, 
        sendChat.value = !sendChat.value, 
        // Wait 300ms for the chat to open
        setTimeout(() => {
            chatInput.value = ''
        }, 300)
        
    }

    watch (showAllDocuments, (n) => {
        if (n) {
            showSearch.value = true
        }
        else if (!n) {
            showSearch.value = false
        }
    })


</script>

<style scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.heading-enter-active,
.heading-leave-active {
  transition: opacity 0.3s ease-out;
  position: absolute;
}

.heading-enter-from,
.heading-leave-to {
  opacity: 0;
}

.settings-enter-active,
.settings-leave-active {
  transition: all 0.2s ease-out;
  position: absolute;
}

.settings-enter-from,
.settings-leave-to {
  opacity: 0;
  transform: translateX(-10%);

}

.projects-enter-active,
.projects-leave-active {
  transition: opacity 0.3s ease-out;
}

.projects-enter-from,
.projects-leave-to {
  opacity: 0;
}

.scale-enter-active,
.scale-leave-active {
  transition: transform 0.15s ease-out;
}

.scale-enter-from,
.scale-leave-to {
  transform: scale(0.1);
}


.gridSize {
    @apply w-full bg-transparent 
}

.ListSize {
    @apply w-full bg-neutral-200 dark:bg-zinc-800 shadow-3xl dark:drop-shadow-3xl
}

.Settings0 {
    @apply  duration-200 ease-out w-0 h-screen m-0
}

.Settings1 {
    @apply rounded-xl duration-200 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 ease-out
}

.EditorFullScreen {
    @apply w-screen h-screen rounded-none duration-1000 ease-in-out top-0
}

</style>