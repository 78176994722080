<template>


</template>

<script setup>

import { useRouter } from 'vue-router'
import getUser from '../composables/getUser'


import { ref, watch, onMounted } from 'vue'

onMounted(async () => {
  

})

</script>