import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Signup from '../views/Signup.vue'
import Login from '../views/Login.vue'
import Welcome from '../views/Welcome.vue'
import Testview from '../views/Testview.vue'
import Landing from '../views/Landing.vue'
import Privacy from '../views/Privacy.vue'
import EditorView from '../views/EditorView.vue'


import { auth } from '../firebase/config'

// Auth Guard

const requireAuth = (to, from, next) => {
  let user = auth.currentUser
//  console.log('current user auth guard: ', user)
  if (!user) {
     next({ name: 'Landing'})
  }
  else {
    next()
  }
  
}

const requireNoAuth = (to, from, next) => {
  let user = auth.currentUser
  if (user) {
    next({ name: 'Home'})
  }
  else {
    next()
  }

}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requireAuth,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    beforeEnter: requireAuth,
  },
  {
    path: '/testview',
    name: 'TestView',
    component: Testview,
    beforeEnter: requireAuth,
  },
  {
    path: '/landing',
    name: 'Landing',
    component: Landing,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    beforeEnter: requireNoAuth,
  }
  ,
  {
    path: '/editor/:id',
    name: 'Editor',
    component: EditorView,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Home' }
}

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
