<template>
    
    <div v-if="user">
        <div class="flex h-screen gap-3 justify-center flex-row p-3">
            <!-- <div @click="closeEditor()" class="flex flex-1 h-screen bg-blue-300 bg-opacity-0 ">
                <div class="module absolute bottom-12 left-0 m-3 transition-height-width flex flex-row flex-nowrap rounded-3xl" 
                        :class="{Settings0: toggleSettings == false, Settings1: toggleSettings == true}">
                    <Settings/>
                </div>
            </div> -->

            <!-- <div @click="showChat = !showChat" class=" w-10 h-10 z-90 flex items-center justify-center btn absolute right-9 top-10 transition duration-500 ease rounded-full  " >
                <div v-if="!loading" class="rounded-full bg-neonyellow  dark:bg-gray-300 dark:border dark:border-blue-800 blur-[1px] hover:blur-0 w-5 h-5 opacity-80 hover:opacity-100 transition duration-200 ease scale-100 hover:scale-110"></div>
                <div v-if="loading" class="dots-6 scale-75 top-10 right-9 fixed"></div>
            </div> -->

            <!-- <div @click="showProjects = !showProjects" class="fixed cursor-pointer left-2 rotate-180 top-1/2 bottom-1/2 opacity-50 hover:opacity-90">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="grey" class="w-5 h-5 ">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                </svg>
            </div> -->

            <div class="transition-position transition-height-width flex   flex-row flex-nowrap rounded-2xl duration-300 ease-out " 
                                        :class="{'md:h-screen90 h-screen90 lg:w-3/12 md:w-2/12 sm:mt-10 ': showProjects, 'h-screen90 w-10': !showProjects}">
                    
                    <div v-if="!showProjects" @click="showProjects = !showProjects" class="bg-neutral-200 dark:bg-zinc-700 w-10 h-10  rounded-full flex justify-center cursor-pointer mt-10 opacity-50 hover:opacity-90">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="grey" class="w-5 h-5 m-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                        </svg>
                    </div>
                    
                     <Transition name="slide">
                        <div class="w-full h-full overflow-scroll rounded-2xl bg-neutral-125 dark:bg-zinc-800" v-if="showProjects">      
                            <div class="w-full sticky z-90 flex flex-row rounded-t-2xl bg-neutral-200 dark:bg-zinc-800 p-1 ">
                                <div @click="showProjects = !showProjects" class="opacity-50 cursor-pointer p-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"  class="w-6 h-6 opacity-40 hover:opacity-80 svgStroke">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                                <textarea  class="w-full p-2 resize-none  bg-neutral-200 dark:bg-zinc-800 h-8 pl-4" v-model="query" type="text" placeholder="Search" @keypress.enter.prevent="complete(input)"></textarea>
                            </div>        
                            <Projects :query="query" :showAllDocuments="showAllDocuments" :ProjectsLayout="ProjectsLayout"/>
                        </div>  
                    </Transition>

                    
                </div>
   
            <Transition name="scale">
                <div v-if="showEditor" class="overflow-hidden bg-neutral-125 dark:bg-zinc-800 transition-position transition-height-width flex flex-row flex-nowrap rounded-2xl duration-300 ease-out " 
                                        :class="{'md:h-screen90 h-screen90 lg:w-4/5 xl:w-8/12 md:w-5/6 w-full sm:mt-10 md:top-10 ': FullScreen == false, EditorFullScreen: FullScreen == true}">
                                        <!-- lg:w-3/5 md:w-5/6 w-full': !showChat, 'lg:w-5/6 md:w-11/12 w-full': showChat}"> -->
                    <Editor @showChat="showChat = !showChat" @update:loading="(n) => loading = n"   @closeEditor="closeEditor()" @toggleFullScreen="toggleFullScreen" />

                </div>
            </Transition>
            
                <div v-if="showEditor" class=" transition-position transition-height-width flex flex-row flex-nowrap rounded-2xl duration-300 ease-out " 
                                        :class="{'md:h-screen90 h-screen90 lg:w-4/12 md:w-3/12 sm:mt-10 ': showChat, 'w-10': !showChat}">
                                <!-- lg:w-3/5 md:w-5/6 w-full': !showChat, 'lg:w-5/6 md:w-11/12 w-full': showChat}"> -->
                        <!-- <div @click="showChat = !showChat" class=" w-10 h-10 z-90 mt-10 flex items-center justify-center btn  transition duration-500 ease rounded-full " >
                            <div v-if="!loading" class="rounded-full bg-neonyellow  dark:bg-gray-300 dark:border dark:border-blue-800 blur-[1px] hover:blur-0 w-5 h-5 opacity-80 hover:opacity-100 transition duration-200 ease scale-100 hover:scale-110"></div>
                            <div v-if="loading" class="dots-6 scale-75 top-10 right-9 fixed"></div>
                        </div> -->
                     <Transition name="slide">
                        <div class="w-full h-full" v-if="showChat">  
                                        
                            <Chat />
                        </div>  
                    </Transition>
                </div>
           
<!--     
                <div  class="transition-height-width ml-4 rounded-t-xl h-screen90 bg-opacity-70 duration-200 ease" 
                                        :class="{'w-0': !showChat, 'lg:w-4/12 md:w-3/12 sm:mt-10 ': showChat}">
                     <Transition name="slide" mode="in-out">
                        <div v-if="showChat">              
                            <Chat />
                        </div>    
                    </Transition>
                </div> -->

            
            <!-- <Transition name="slide">
                <div v-if="showChat"  class="transition-height-width border-l rounded-xl h-screen bg-opacity-70 duration-500 ease absolute lg:w-3/12 md:w-2/12 w-0 right-0 top-10" >
                    <Chat />
                </div>               
            </Transition>   -->

        </div>  
    
        <Transition>
            <Footer v-if="!FullScreen && !showChat" @toggleSettings="toggleSettings = !toggleSettings" />
        </Transition>
    </div>
    
    </template>
    
    <script>
    
    import Projects from '@/components/Projects.vue'
    import Editor from '@/components/Editor.vue'
    import Footer from '@/components/Footer.vue'
    import Settings from '@/components/Settings.vue'
    import Chat from '@/components/Chat.vue'
    import Landing from '@/views/Landing.vue'
    import { useRouter } from 'vue-router'
    import getUser from '../composables/getUser'
    import { useSelectedProject } from '../composables/useSelectedProject'
    import { collection, addDoc, serverTimestamp, deleteDoc, updateDoc, doc, getDoc} from '@firebase/firestore'
    import { db } from '../firebase/config'
    
    import { computed, onMounted, ref, watch , watchEffect} from 'vue'
    
    export default {
      name: 'EditorView',
      components: {
        Projects,
        Editor,
        Footer,
        Settings,
        Chat,
        Landing
      },
      
      setup(context, props) {
        const router = useRouter()
        const {user} = getUser()
        const { selectedGlobalProject, updateSelectedGlobalProject } = useSelectedProject()
        const showProjects = ref(false)
        const showEditor = ref(false)
        const showChat = ref(false)
        const toggleSettings = ref(false)
        const newProject = ref(false)
        const ProjectsLayout = ref('List')
        const FullScreen = ref(false)
        const startSearch = ref(false)
        const loading = ref(null)
        const showAllDocuments = ref(true)
        const query = ref('')
        //const id = ref(props.id);
        //const id = computed(() => router.currentRoute.value.params.id);

        watch(query, (newQuery) => {
            if (newQuery.length > 0) {
                showAllDocuments.value = false
            } else {
                showAllDocuments.value = true
            }
        })

        onMounted(() => {

            console.log('mounted')
            // Set showEditor to true after 200ms
            setTimeout(() => {
                showEditor.value = true
            }, 100)
            checkNewUser()
            
           
            
        })

        // const docID = computed(() => {
        //     return props.id
        // })

        const checkNewUser = async () => {
            const userRef = doc(db, 'users', user.value.uid);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                //console.log('Document data:', docSnap.data());
                if (docSnap.data().usageCount < 500) {
                    showChat.value = true
                }
            } else {
                // doc.data() will be undefined in this case
                console.log('No such document!');
            }
        }
    
    
        const toggleFullScreen = () => {
            showChat.value = false
            FullScreen.value = !FullScreen.value
        }
    
        // If the user value is ever null, redirect to welcome screen
        watch(user, () => {
          if (!user.value) {
            router.push({ name: 'Landing' })
          }
        })
        
    
        const closeEditor = () => {
            console.log('closeEditor')
            showEditor.value = false, 
            // Wait for the animation to finish
            setTimeout(() => {
                router.push('/')
            }, 150)
            
            //updateSelectedGlobalProject(null)
            }
    
        const toggleLayout = () => {
            if (ProjectsLayout.value == 'Grid') {
                ProjectsLayout.value = 'List'
            }
            else if (ProjectsLayout.value == 'List') {
                ProjectsLayout.value = 'Grid'
            }
        }
    
        return {
          showEditor, 
          showProjects,
          showChat,
          toggleFullScreen,
          toggleSettings,
          newProject,
          ProjectsLayout,
          closeEditor,
          toggleLayout,
          selectedGlobalProject,
          FullScreen,
          user,
          startSearch,
          router,
          loading,
          showAllDocuments,
          query,
          ProjectsLayout
          }
      }
    }
    
    </script>
    
    <style scoped>
    
    .v-enter-active,
    .v-leave-active {
      transition: opacity 0.15s ease-out;
    }
    
    .v-enter-from,
    .v-leave-to {
      opacity: 0;
    }
    
    .scale-enter-active,
    .scale-leave-active {
      transition: transform 0.15s ease-out;
    }
    
    .scale-enter-from,
    .scale-leave-to {
      transform: scale(0.1);
    }

    .slide-enter-active,
    .slide-leave-active {
    transition: transform 0.2s ease-out;
    }

    .slide-enter-from,
    .slide-leave-to {
    transform: scale(0.1);
    
    }
    
    /* Animation for Transition of Modules */
    
    .gridSize {
        @apply w-full bg-transparent 
    }
    
    .ListSize {
        @apply w-full bg-neutral-200 dark:bg-zinc-800 shadow-3xl dark:drop-shadow-3xl
    }
    
    .Settings0 {
        @apply w-60 h-56  duration-500 ease-out -left-80
    }
    
    .Settings1 {
        @apply w-60 h-56 rounded-xl duration-500 ease-out
    }
    
    .EditorFullScreen {
        @apply w-screen h-screen rounded-none duration-1000 ease-in-out top-0 absolute
    }
    
    </style>