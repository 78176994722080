<template>


<div class="flex-col w-full m-auto">
    <div v-if="error">{{ error }}</div>
    
    <div class="flex justify-center ">
        <Transition>
            <div v-if="Question" class="mb-18 top-20 sm:top-30 md:mb-0 absolute question text-center w-4/5 md:w-1/2 bg-neutral-200 p-4 py-6 rounded-2xl">{{Question}}</div>
        </Transition>
    </div>

    <div class="flex justify-center mb-12">
        <Transition>
            <div v-if="showTopics" class="w-4/5 md:w-1/2 flex flex-row flex-wrap items-center justify-center bottom-10 m-auto">
                <div v-for="topic in topics" :key="topic.value">
                    <div class="flex justify-center">
                        <button @click="setTopic(topic)" class="topicButton" :class="{ topicActive: topic.value }">{{topic.name}}</button>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
    
    <Transition>

    <div v-if="showInput" class="flex justify-center">
        <div class="w-1/3 h-10 relative">
        <!-- <textarea placeholder="Type somthing..." class="resize-none absolute p-4 h-10 w-full rounded-full bg-white bg-opacity-30 shadow-2xl" v-model="input" type="text" @keypress.enter.prevent="complete(input)"> </textarea> -->
        <textarea placeholder="Type somthing..." class="absolute p-2 resize-none rounded-full bg-[#eeeeee] bg-opacity-70 shadow-2xl w-full h-10 pl-4" v-model="input" type="text" @keypress.enter.prevent="complete(input)"></textarea>

        <div @click.prevent="complete(input)" class="absolute btn right-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="grey" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
            </svg>
        </div>
        </div>
    </div>
    </Transition>

    <div  class="flex justify-center">
         <Transition>
            <div v-if="showNo" @click.prevent="handleNo()" class="btn m-1 w-14 h-14 bg-neutral-200">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="grey" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </Transition>

        <Transition>
            <div v-if="showButton" @click.prevent="nextQuestion(1)" class="btn m-1 w-14 h-14 bg-neutral-200 text-lg">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="grey" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                </svg>
            </div>
        </Transition>
    </div>

    
  <p v-if="questionCounter > 2" @click="skip()" class="btn fixed bottom-0 left-0 m-2 p-2">
    Skip
  </p>

 <p  class="p btn fixed bottom-0 right-0 m-2 p-2">
    {{questionCounter}} / 7
  </p>
    

</div>


</template>

<script> 
import { useRouter, useRoute } from 'vue-router'
import Completion from '../composables/Completion'
import getUser from '../composables/getUser'

import { collection, addDoc, serverTimestamp, deleteDoc, updateDoc, doc, getDoc} from '@firebase/firestore'


import { computed, onMounted, onUpdated, ref, watch } from 'vue'
import { db } from '@/firebase/config'

export default {


    setup() {
        const {user} = getUser()
        const show = ref('')
        const input = ref('')
        const output = ref('')
        const conversation = ref('The following is a conversation between an AI and a person. The goal of this chat is to find out how the person writes.\n\nHello ' + user.value.displayName + '. Do you write sometimes?')
        const showText = ref('')
        const loading = ref('')
        
        const prePhrases = ref(true)
        const Question = ref('')
        const showInput = ref(false)
        const showButton = ref(false)
        const questionCounter = ref(0)
        const showNo = ref(false)
        const showTopics = ref(false)
        const error = ref(null)

        const topics = ref([
            {name: 'Technology', value: false},
            {name: 'Politics', value: false},
            {name: 'Sports', value: false},
            {name: 'Design', value: false},
            {name: 'Music', value: false},
            {name: 'Nature', value: false},
            {name: 'Movies', value: false},
            {name: 'Food', value: false},
            {name: 'Travel', value: false},
            {name: 'Fashion', value: false},
            {name: 'Gaming', value: false},
            {name: 'Art', value: false},
            {name: 'Science', value: false},
            {name: 'History', value: false},
            {name: 'Business', value: false},
            {name: 'Health', value: false},
            {name: 'Education', value: false},
            {name: 'Entertainment', value: false},
            {name: 'Other', value: false},
        ])

        // Create a list of common online newspaper, blogs and magazines

        const mediaList = ref([
            {name: 'The New York Times', value: false},
            {name: 'The Washington Post', value: false},
            {name: 'The Guardian', value: false},
            {name: 'The Wall Street Journal', value: false},
            {name: 'The Economist', value: false},
            {name: 'The Atlantic', value: false},
            {name: 'The New Yorker', value: false},
            {name: 'Wired', value: false},
            {name: 'The Verge', value: false},
            {name: 'Business Insider', value: false},
            {name: 'Sueddeutsche Zeitung', value: false},
            {name: 'Die Zeit', value: false},                  
            ])
        

        const setTopic = (topic) => {
            // Change the value of the topic to true if the user is interested in it
            topic.value = !topic.value
        }

        onMounted(() => {

            console.log('Chat mounted')
            textAnimation()
            })
        

        const textAnimation = () => {

            // If user.value.displayName is more than two words, only use the first word
            if (user.value.displayName.includes(' ')) {
                var name = user.value.displayName.split(' ')[0]
            } else {
                var name = user.value.displayName
            }

            Question.value = 'Hello ' + name + ', this is a calm place for collecting ideas and bringing them into coherent stories.'
            
            // Wait 1 second and set the Question to the next one

            setTimeout(() => {
                showButton.value = true
            }, 1000)               
        }

        // Watch questionCounter for changes and jump to the onboarding step

        watch(questionCounter, (newValue) => {

            let questionDelay = 800
            let buttonDelay = 1200


            if (newValue == 1) {

                Question.value = ''
                showButton.value = false
                
                setTimeout(() => {
                Question.value = 'In order to provide a tailored experience it would be helpful to find out about what interests you. Would you like to give some insights?'
                }, questionDelay) 

                setTimeout(() => {
                showButton.value = true
                showNo.value = true
                }, buttonDelay)   
            }
            if (newValue == 2) {
                Question.value = ''
                showButton.value = false
                showNo.value = false
                setTimeout(() => {
                showTopics.value = true
                Question.value = 'Are you interested in some of these topics? If so just click on them.' 

                }, questionDelay) 

                setTimeout(() => {
                showButton.value = true
                showInput.value = false
                }, buttonDelay - 1000)   
            }
            if (newValue == 3) {
                Question.value = ''
                showButton.value = false
                showNo.value = false

                // Delete all the topics that are not selected
                topics.value = topics.value.filter(topic => topic.value == true)

                // Write the topics array to the database
                
                const docRef = doc(db, 'users', user.value.uid)
                updateDoc(docRef, {
                    topics: topics.value
                })

                setTimeout(() => {
                showTopics.value = false
                Question.value = 'Do you sometimes write or would like to?' 

                }, questionDelay) 

                setTimeout(() => {
                showButton.value = false
                showInput.value = true
                }, buttonDelay - 1000)   
            }
            if (newValue == 7){

                setTimeout(() => {
                Question.value = 'This should be enough to get started. You can always change your preferences in the settings.'
                }, 2000) 

                setTimeout(() => {
                    skip()           
                }, 5000)    
            }})

        const nextQuestion = (input) => {
            questionCounter.value = questionCounter.value + input
            showButton.value = false
            showNo.value = false
            showTopics.value = false
        }

        const handleNo = () => {
            showNo.value = false
            Question.value = ''
            skip()
        }

        const skip = () => {
                router.push('/')
            }

// OpenAI completion and Firebase storage

        const complete = async (i) => {

            nextQuestion(1)

            Question.value = ''

            if (questionCounter.value < 7) {
               
            const prompt = conversation.value + user.value.displayName + ": " + i + "\nAI: "

            const colRef = collection(db, 'chat')
            await addDoc(colRef, {
                name: user.value.displayName,
                userId: user.value.uid,
                message: input.value,
                createdAt: serverTimestamp()
            })
            if (!error.value) {
                input.value = ''
            }

            prePhrases.value = false;

            loading.value  = true;
        
            const stop1 = 'Human: '
            const output = await Completion(prompt, "text-davinci-002", 50 , 'AI: ' )

            conversation.value = prompt + output.completion
            Question.value = output.completion

            loading.value  = false;
            
            console.log(output.completion)

            await addDoc(colRef, {
                name: 'AI',
                userId: user.value.uid,
                message: output.completion,
                createdAt: serverTimestamp()
            })

        }}

// Router initialization

        const router = useRouter()
        const route = useRoute()

//auto-scroll
        const messages = ref(null)



        return {show, 
        input, 
        complete, 
        output, 
        conversation, 
        showText, 
        loading, 
        user, 
        prePhrases,
        error, 
        messages,
        Question,
        textAnimation,
        showInput,
        showButton,
        questionCounter,
        nextQuestion,
        skip,
        showNo,
        handleNo,
        topics,
        showTopics,
        setTopic
        }
    },

      methods: {
      redirectTo (pathname) {
        this.$router.push(
            {
              path: '/' + pathname,
            }
     )
 }

  }

}
</script>

<style scoped>

/* Animation for Transition of components */
.v-enter-active,
.v-leave-active {
  transition: all 0.4s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.topicButton {
    @apply border-2 border-zinc-200 bg-zinc-200 text-zinc-400 font-semibold py-2 px-4 m-2 rounded-full ;
}

.topicActive {
    @apply border-2 border-indigo-400 text-zinc-400 font-semibold py-2 px-4 m-2 rounded-full ;
}

</style>