import { ref } from 'vue'

const LanguageGlobal = ref('English')

export function useLanguageGlobal() {
  function updateLanguageGlobal(newState) {
    LanguageGlobal.value = newState 
  }

  return {
    LanguageGlobal,
    updateLanguageGlobal
  }
}
