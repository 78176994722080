import {ref, watchEffect} from 'vue'

//firebase imports
import { db } from '@/firebase/config'
import { collection, onSnapshot, query, orderBy, where} from 'firebase/firestore'

const getCollection = (collectionName, user, ) => {

//    console.log('getCollection called')
    const documents = ref([])
    const error = ref(null)
    
    let colRef = collection(db, collectionName)

    if (user && collectionName == 'notes') {
        colRef = query(colRef, where(...user)
        , orderBy('editedAt', 'desc'))
    }

    if (user && collectionName == 'chat') {
        colRef = query(colRef, where(...user))
    }

    const unsub = onSnapshot(colRef, (snapshot) => {
        let docs = []
        snapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id})
        })
        documents.value = docs
        error.value = null
    }, (err) => {
        console.log(err.message)
        error.value = 'Could not load Data'
    })

    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())
        })
    
    return { documents, error }
}

export default getCollection

  