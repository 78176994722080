<template>
<div class="flex justify-center h-full bg-neutral-150 overflow-scroll">
    <div class="flex-col m-auto w-full items-center">
        <div class="w-full h-12">
            <div class="flex justify-between">
                <div class="flex justify-center items-center">

                    <!-- <div class="flex justify-center items-center m-2">
                        <div class="w-3 h-3 rounded-full border-2 border-neutral-500 "></div>
                        <div class="w-3 h-3 mx-px rounded-full border-2 border-neutral-500 "></div>
                        <div class="w-3 h-3 rounded-full border-2 border-neutral-500 "></div>
                    </div> -->

                     <!-- <div class="flex flex-col justify-center m-4 mt-3 transition ease duration-300">
                        <div class="w-7 hover:w-3 h-3 transition-width ease duration-500 rounded-full m-px bg-neutral-300"></div>
                        <div class="w-5 hover:w-3 h-3 transition-width ease duration-500 rounded-full m-px bg-neutral-400"></div>
                        <div class="w-3 h-3 rounded-full m-px bg-neutral-600"></div>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="flex justify-center mt-6 sm:mt-12 m-12">
            <div class="flex-col justify-center antialiased text-center items-center ">
                <div class="text-neutral-800 text-6xl md:text-9xl">A tandem</div>
                <div class="text-neutral-300 text-6xl  md:text-9xl">for your mind<span class="font-serif">.</span></div>
            </div>
        </div>

         <div class="flex justify-center top-12 mt-12 mb-12">
            <div class="flex-col justify-center text-center items-center w-8/12 md:w-6/12">
                <!-- <div class="text-neutral-300 text-2xl">Fuse is a personal notes and writing assistant for <span class="line-through">content</span> creators that works with you, not for you.</div> -->
                <!-- <div class="text-neutral-300 text-2xl">Fuse is an AI-powered creative assistant that helps to bring raw ideas into coherent stories. It adapts to your personal style without hurting your privacy or taking away your agency.</div> -->
                <Transition>  
                    <div class="text-neutral-500" :class="{'text-left bg-neutral-300 p-4 rounded-2xl': answer}">
                       <div v-if="!answer" class="md:text-lg text-lg">{{startOutput}}</div>
                        <div v-if="answer" class="text-xl text-neutral-800">{{output.currentText}}</div>
                    </div>
                </Transition>
                
                <!-- <Transition>  
                    <div v-if="answer" class="text-2xl text-left bg-neutral-300 p-4 rounded-2xl ">
                        <div  class="text-xl">{{output.currentText}}</div>
                    </div>
                </Transition> -->
                <!-- <div class="text-neutral-300 text-2xl">Fuse is an AI based notes and writing assistant that thinks with you, not for you.</div> -->
            </div>
        </div>

        <div class="flex flex-col items-center justify-center mt-24 m-10">
            <div class="flex w-full justify-center gap-3">
            <div class="bg-[#DBFF00] flex justify-center items-center rounded-full transition-height-width ease duration-500" 
                    :class="{'lg:w-2/12 md:w-4/12 w-3/5': !showInput && !showSmiley, 'w-4/6 md:w-4/12': showInput && !showSmiley, 'w-12 h-12': showSmiley && !showInput}">
                <div  @click="showInput = true" v-if="!showInput && !showSmiley"  class="text-gray-800 p-1 text-center text-[15px] mx-3 cursor-pointer truncate m-auto ">Join the Waitlist</div>
 
                <div v-if="showInput" class="flex w-full justify-between">
                    <!-- <input @keydown.enter.prevent="storeWaitlist()" v-model="name" placeholder="Name" class="bg-transparent p-2 w-3/6 ml-3"  type="text"> -->
                    <input @keydown.enter.prevent="storeWaitlist()" type="email" autofocus v-model="email" placeholder="Email" class="bg-transparent ml-3 p-2 w-4/6">
                    <button @click="storeWaitlist()" class="w-1/6">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="w-6 h-6 float-right mr-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                        </svg>
                    </button>
                </div>
       

                <Transition name="rotate">       
                    <div v-if="showSmiley" class="w-6 h-6 m-auto">   
                        <svg   xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 16"><g fill="currentColor"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75a.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25a.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/></g></svg>
                    </div>
                </Transition>
            </div>

            <div  class="bg-neutral-300 flex justify-center items-center rounded-full transition-height-width ease duration-500 lg:w-2/12 md:w-4/12 w-3/5">
                <div @click="complete('I dont get it...'), showChatInput = true" v-if="!showChatInput" class="text-gray-800 p-1 text-center text-[15px] mx-3 cursor-pointer truncate m-auto ">I don't get it</div>

                <div v-if="showChatInput && answer" class="flex w-full justify-between">
                    <!-- <input @keydown.enter.prevent="storeWaitlist()" v-model="name" placeholder="Name" class="bg-transparent p-2 w-3/6 ml-3"  type="text"> -->
                    <input @keydown.enter.prevent="complete(question), question = ''" autofocus v-model="question" placeholder="Ask something" class="bg-transparent ml-3 p-2 w-5/6">
                    <button v-if="!loading" @click="complete(question), question = ''" class="w-1/6">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="w-6 h-6 float-right mr-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                        </svg>
                    </button>
                </div>

                <div v-if="loading" class="dots-6 scale-50"></div>


            </div>
            
        </div>
<!-- 
        <Transition>  
            <div v-if="answer" class="mt-4 bg-neutral-300 p-3 rounded-2xl w-1/3 text-xl">{{answer}}</div>
        </Transition> -->

        <!-- <Transition>  
            <div v-if="answer" class="mt-4 bg-neutral-300 p-3 rounded-2xl w-1/3 text-xl">{{outputAnswer}}</div>
        </Transition> -->

        <!-- <Transition>  
            <div v-if="answer" class="transition-all duration-300 ease-in-out overflow-hidden mt-4 bg-neutral-300 p-4 rounded-2xl lg:w-1/3 w-11/12 ">
            <div  class="text-xl">{{output.currentText}}</div>
            </div>

        </Transition> -->


       

        <Transition>  
            <div v-if="message" class="mt-4 w-2/3 m-auto text-center text-xl">{{message}}</div>
        </Transition>
            <div v-if="error" class="mt-4 text-xl">{{error}}</div>
       
        </div>

        <div class="flex m-auto justify-center w-full relative h-screen30 sm:h-screen50 lg:h-screen80">
        <!-- <img class="object-cover z-50" src="@/assets/Mockup/ipad.png" alt=""> -->
            <!-- <video playsinline  id="vid" class="absolute m-auto left-0  right-0 w-10/12 md:w-4/6 hover:md:w-9/12 rounded-xl lg:rounded-3xl border-4 lg:border-8 mt-24 md:mt-8 border-neutral-800 transition-height-width ease duration-500"  autoplay muted loop>
                <source src="@/assets/Video/Writing.mov" type="video/mp4">           
                Your browser does not support the video tag.
            </video> -->

            <img src="@/assets/Mockup/VC01.png" alt="VC01" class="absolute m-auto left-0  right-0 w-10/12 md:w-4/6 hover:md:w-9/12 rounded-xl lg:rounded-3xl border-0 lg:border-0 mt-24 md:mt-8 border-neutral-800 transition-height-width ease duration-500">
           

        </div>

        <!-- <div class="flex m-auto justify-center w-2/3 text-center mt-12">
            <div class="text-neutral-800 text-6xl">A machine won't solve your mental block. But it can make it easier to get through them.</div>
        </div> -->

        <!-- <div class="flex m-auto justify-center w-full text-center mt-12">
            <div class="text-neutral-800 text-2xl w-1/3">Compared to many other genertive AI tools, Fuse focuses on human-machine collaboration instead of automation. </div>
        </div> -->

        <!-- <div class="flex justify-center top-12 m-12">
            <div class="flex-col justify-center text-center items-center w-4/5 md:w-3/5">
                <div class="text-neutral-600 text-xl">One of the biggest issues surrounding generative AI today is that it does not give enough agency to humans. Fuse is based on the principle of having AI as a sidekick that ideally triggers creativity instead of making decisions for you. Language is jsut the start.</div>

                <div class="text-neutral-300 text-2xl">Fuse is an AI based notes and writing assistant that thinks with you, not for you.</div>
            </div>
        </div> -->

        <div class="flex justify-center top-12 m-12 mt-32">
            <div class="flex-col justify-center text-center items-center w-5/6 md:w-2/5">
                <!-- <div class="text-neutral-300 text-2xl">Fuse is a personal notes and writing assistant for <span class="line-through">content</span> creators that works with you, not for you.</div> -->
                <!-- <div class="text-[#C5C5C5] text-xl">“The most powerful person in the world is the storyteller. The storyteller sets the vision, values and agenda of an entire generation that is to come.” S. J.</div> -->

                <!-- <div class="text-neutral-300 text-2xl">Fuse is an AI based notes and writing assistant that thinks with you, not for you.</div> -->
            </div>
        </div>


         <div class="w-full mt-24"> 
            <div class="flex-col flex justify-center items-center mt-6">
                <a href="https://tame.ooo"><img class="w-20" src="@/assets/Logo/tamelabs.png" alt=""></a>
                <div class="m-1 mt-4 text-xs">©2023</div>
                <div @click="router.push('/privacy')" class="m-2 mb-4 text-xs cursor-pointer">Privacy Policy</div>
            </div>
        </div> 
    </div>
</div>

<div v-if="showCookies">
    <Cookies @Accept="showCookies =!showCookies" @Decline=" showCookies =!showCookies"/>
</div>


</template>

<script>

import { ref, watch, onMounted } from 'vue'
import { db } from '../firebase/config'
import { collection, addDoc, serverTimestamp, doc, updateDoc} from '@firebase/firestore'
import { useRouter, useRoute } from 'vue-router'
import Cookies from '@/components/Cookies.vue'
import Completion from '../composables/Completion'
import useTypewriter from '@/composables/useTypewriter'
import ChatCompletion from '@/composables/ChatCompletion'


export default {
    name: 'Landing',
    components: {
        Cookies
    },
    setup() {
        let showInput = ref(false);
        const name = ref('')
        const email = ref('')
        const error = ref(null)
        const message = ref('')
        const router = useRouter()
        const showCookies = ref(true)
        const showSmiley = ref(false)
        const conversation = ref([{role: 'system', content: 'The following is a conversation between an AI and a person. The AI is friendly and helpful but also reserved. It likes to answer short and precise and is embedded into a Landing Page of a product called Fuse. If a person doesnt understand the concept at first it will explain a story of how famous artist work with assistants rather than explaining plain fact. These are the guidelines:\n\nFuse is a creative assistant that aims to help creators in their work processes. It aims to assist from the first idea, to gathering research and organising that to coming up with with new idea and executing them. In language, image and audio. The goal is to provide a more personalised experience than ChatGPT and provide a UI that looks minimal and nice. Also Fuse takes personal privacy seriously when it comes to AI interaction. While offering a personalised experience Fuse doesnt steal peoples data and sell it to third parties. If a person asks how AI can help in their creative processes dont tell them fact but a story: When artists such as Renaissance painters created their work, they had many workers who actually painted for them. What they did in the end was directing the painted image and maybe giving it a final touch. And what AI can do is giving these assistants to creators who cant afford human assistants and still enable them to have a bigger output and a better quality of their work as they can concentrate more on the storytelling and distribution of their work.\n\n'}])
        // const conversation = ref('The following is a conversation between an AI and a person. The AI is friendly and helpful but also serious and a bit cool. It likes to answer short and precise and is embedded into a Landing Page of a product called Fuse. It will only answer questions about Fuse based on these guidelines:\n\nFuse is a creative assistant that combines personal knowledge management (notes) with adaptive text generation (e.g. autocompletions) and in the beginning focuses on bringing raw ideas into a coherent story. For example by suggesting autocompletions for writers or helping them to bring raw notes into a structured narrative. It also has a chat interface in order to do basic research or store quick notes that can be searched like a personal search engine. Fuse learns from all past ineractions and adapts its style and behaviour accordingly. \nIt also has a high standard for protecting privacy as personal ideas are one of the most valuable goods for individuals. The key philosophy of Fuse is to empower creators by helping them in their creative process without taking away human agency. \nAt some point Fuse will integrate other modalities like audio and image but in the beginning it focuses on writing tasks. This can be for personal bloggers, designer, journalists, creative writers, marketers or just in general content-creators. The goal of the conversation is to answer the visitors questions regarding the use-cases of Fuse. The AI should not flood the person with facts about Fuse but rather ask them about their process to find out where Fuse could help.\n\n')
        const loading = ref(false)
        const answer = ref('')
        const showChatInput = ref(false)
        const question = ref('')
        const outputAnswer = ref('')
        //const { typeWriter } = useTypewriter()
        const output = ref('')
        const { currentText } = useTypewriter('')
        const startOutput = ref('Fuse is an AI-powered creative assistant. It helps to surface your previous ideas and collaborates on new ones.')
        const docRef = ref(null)

        

        watch (answer, (val) => {
            if (val) {

                // Update currentText with new value and use the typewriter function
                currentText.value = val
                output.value = useTypewriter(currentText.value, 17)
                
                //outputAnswer.value = useTypewriter('Testvalue', )
            }
        })


        // Function to store name and email in firebase waitlist

        const storeWaitlist = async () => {

            error.value = null


            // If email is not valid
            if (email.value.includes('@') && email.value.includes('.') && email.value.length > 6) {
                                        
            
            const colRef = collection(db, 'waitlist')
            await addDoc(colRef, {
                name: name.value,
                email: email.value,
                createdAt: serverTimestamp()
            })

            if (!error.value) {
                name.value = ''
                email.value = ''
                message.value = 'Waitlist added'
                showInput.value = false
                showSmiley.value = true
            }
            }
            else {
                error.value = 'Please enter a valid email'
            }
        }
        const complete = async (i) => {
        loading.value = true

        const newMessage = {role: 'user', content: i}
            conversation.value.push(newMessage)

        //const prompt = conversation.value + "\n\nHuman: " + i + "\n\nAI: "

             console.log('conversation.value: ', conversation.value)
            let output = await ChatCompletion(conversation.value)
            console.log(output.completion.content)

        // console.log(prompt)
        // let output = await getCompletion(prompt, "text-davinci-003", 100 , 'AI: ', '', 0.8, 0.5, 0.6, 1 )
        // //let output = await Completion(prompt, "curie-instruct-beta", 100 , 'AI: ')
        // console.log(output)

        conversation.value.push({role: 'user', content: output.completion.content})
            answer.value = output.completion.content

            
            loading.value = false

        // conversation.value = prompt + output.completion
        // answer.value = output.completion

        loading.value = false

        if (docRef.value == null) {

        try {
        const colRef = collection(db, 'IdontGetIt')
        docRef.value = await addDoc(colRef, 
          {
            prompt: i,
            completion: output.completion.content,
            createdAt: serverTimestamp()
          })
        console.log(docRef.value.id)
        
         
        }
        catch(err) {
          console.log(err.message)
        }
    }

    else if (docRef.value) {

        try {

        await updateDoc(doc(db, 'IdontGetIt', docRef.value.id), {
            prompt: prompt,
            completion: output.completion.content,
            createdAt: serverTimestamp()
        })

        }
        catch(err) {
          console.log(err.message)
        }
    }
}

        async function getCompletion(input, model, length, stopCharacter, suffixPrompt, temperature, frequency, presence, top_p ) {
        let output = null;
        let retries = 0;
        const maxRetries = 5;
        const retryInterval = 1000;

        while (!output && retries < maxRetries) {
            try {
            output = await Completion(input, model, length, stopCharacter, suffixPrompt, temperature, frequency, presence, top_p );
            } catch (error) {
            if (error.message && error.message.includes("Request failed with status code 429")) {
                retries++;
                console.log(`Too many requests, retrying... (${retries}/${maxRetries})`);
                await new Promise(resolve => setTimeout(resolve, retryInterval));
            } else {
                throw error;
            }
            }
        }

        return output;
        }

        return { 
            showInput,
            name,
            email,
            storeWaitlist,
            error,
            message,
            router,
            showCookies,
            showSmiley,
            complete,
            loading,
            answer,
            showChatInput,
            question,
            outputAnswer,
            output,
            currentText,
            startOutput,
            docRef

            

             }
    }

}
</script>

<style scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 1.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}



/* Transition that rotates */

.rotate-enter-active,
.rotate-leave-active {
  transition: transform 1s ease;
 
}

.rotate-enter-from,
.rotate-leave-to {
  transform: rotate(540deg);
  -webkit-transform: rotate(540deg);

}


</style>