
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: "so-muse.firebaseapp.com",
    projectId: "so-muse",
    storageBucket: "so-muse.appspot.com",
    messagingSenderId: "545903451552",
    appId: "1:545903451552:web:c59193a0a2dd3ca94258c5",
    measurementId: "G-DET6C9JLTB"
  };

  //initialize firebase

    const app = initializeApp(firebaseConfig);

  //initialize services

    const db = getFirestore();
    const auth = getAuth();
    const storage = getStorage();
    const functions = getFunctions();
    const analytics = getAnalytics(app);


  export { db, auth, storage, functions };