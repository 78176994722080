// const newProject = async () => {
//     const colRef = collection(db, "notes");
//     const docRef = await addDoc(colRef, {
//         author: user.value.displayName,
//         userId: user.value.uid,
//         title: "",
//         content: "",
//         prompt: "",
//         summary: "",
//         createdAt: serverTimestamp(),
//         editedAt: serverTimestamp(),
//         isPinned: false,
//         selected: false,
//         tags: ["", "", ""],
//     });
//     selectedProjectId.value = docRef.id;
//     // Get document with ID docRef.id and select it
//     const docSnap = await getDoc(doc(db, "notes", docRef.id));
//     selectProject(docSnap);
// };


import { ref } from 'vue';
import { collection, addDoc, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import getUser from '../composables/getUser'
import {useSelectedProject} from '../composables/useSelectedProject'
import { db } from '@/firebase/config'
import getCollection from '../composables/getCollection'

export function useNewDocument () {

  const {user} = getUser()
  const { selectedGlobalProject, updateSelectedGlobalProject } = useSelectedProject();

  function formatDocument(docSnap) {
    return {
      ...docSnap.data(),
      id: docSnap.id,
    };
  }


  const createNewDocument = async (title, content, prompt, conversation, summary) => {
    console.log('useNewDocument content: ', content)
    const colRef = collection(db, 'notes');
    const docRef = await addDoc(colRef, {
      author: user.value.displayName,
      userId: user.value.uid,
      title: title,
      content: content,
      prompt: prompt,
      conversation: conversation,
      summary: summary,
      createdAt: serverTimestamp(),
      editedAt: serverTimestamp(),
      isPinned: false,
      selected: false,
      tags: ['', '', ''],
    });
    //selectedProjectId.value = docRef.id;
    // Get document with ID docRef.id and select it
    //const docSnap = await getDoc(doc(db, 'notes', docRef.id));

    const docSnap = await getDoc(doc(db, 'notes', docRef.id));
    const formattedDoc = formatDocument(docSnap);

    // Add docRef.id to the docSnap object
    //docSnap.data().id = docRef.id;


    console.log('docSnap: ', formattedDoc)
    // Select the project
    updateSelectedGlobalProject(formattedDoc);
  };

  return {
    createNewDocument,
  };
};

