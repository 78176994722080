// import { ref } from 'vue'

// function useTypewriter(text, speed) {

//     var i = 0;
//     let output = ref('')

//     if (i < text.length) {
         
//         output += text.charAt(i);
//         i++;
        
//         setTimeout(useTypewriter, speed);
//         return output
//     }
// }



// export default useTypewriter

// import { ref } from 'vue'
// let output = ref('')

// export function useTypewriter() {

//   function typeWriter(input, speed) {
//     var i = 0;
//     let text = input
//     console.log(text.length)
    
//     if (text.length && i < text.length) {
         
//         output.value += text.charAt(i);
//         i++;
        
//         setTimeout(typeWriter, speed);
//         return output.value
        
//     }
//   }

//   return {
//     typeWriter,
    
//   }
// }


import { ref } from 'vue';

export default function useTypewriter(text, speed) {
  const index = ref(0);
  const currentText = ref('');


  if (speed === undefined) {
    speed = 20;
    }

  const typewriter = () => {
    if (index.value < text.length) {
      currentText.value += text[index.value];
      index.value++;
      setTimeout(typewriter, speed);
    }
  };

  setTimeout(typewriter, speed);

  return {
    currentText
  };
}
