<template>
<div class="flex-col justify-between w-full h-full rounded-t-2xl bg-opacity-0 bg-neutral-200 dark:bg-zinc-800 ">

  <div class="flex-col w-full h-full  justify-center rounded-t-2xl">
      <div v-if="error">{{ error }}</div>

      <div class="w-full h-full  overflow-scroll p-4 justify-center flex-col items-center scroll-smooth " ref="scrollMessages">

        <div class="flex w-full justify-center  transition-rotation duration-300 ease ">
            <div @click="showMoreMessages(10)" class="bg-transparent cursor-pointer rounded-full w-10 h-10 rotate-180 btn transition-rotation duration-300 ease opacity-40 hover:opacity-60 hover:bg-neutral-200 dark:bg-zinc-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="grey" class="">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
            </div>
        </div>


        <div v-if="localDocuments" v-for="doc in localDocuments" :key="doc.id" class="bg-neutral-100 shadow-4xl group px-6 py-6 mt-4 mb-4 m-auto rounded-xl bg-opacity-60 transition ease-in-out w-fit  max-w-[90%] break-normal "
          :class="{'ml-2 ': doc.name == 'AI', 'mr-2 ': doc.name != 'AI'}">

            <div v-if="doc.name == 'AI'" @click.stop.prevent="handleNewDocumentClick(doc.message)" class="ml-2 float-right w-8 h-8 invisible group-hover:visible btn btnbg">
              <div class=" opacity-40 hover:opacity-80">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="black" class="w-4 h-4" ><path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" /></svg>
              </div>
            </div>

          <div v-if="doc.name == 'AI'" class="flex flex-row">
            <p class="p pt-1 prose whitespace-pre-line">{{ doc.message }}</p>
          </div>

          <div v-if="doc.name!== 'AI'" class="flex flex-row-reverse">
            <p class="p pt-1 prose whitespace-pre-line">{{ doc.message }}</p>
          </div>

        </div>
        <div v-if="loading"><div class="loader opacity-80 scale-100 bottom-10 ml-10" style="width:30px;--c1:#FFFFFF;--c2:#FFFFFF;--c3:#FFFFFF;--c4:#FFFFFF"></div></div>

      </div>

  </div>

</div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import Completion from '../composables/Completion'
import getUser from '../composables/getUser'
import useCollection from '../composables/useCollection'
import { collection, addDoc, serverTimestamp, deleteDoc, updateDoc, doc, getDoc} from '@firebase/firestore'
import getCollection from '../composables/getCollection'
import { computed, onMounted, onUpdated, ref, watch } from 'vue'
import { formatDistanceToNow } from 'date-fns'
import { db } from '@/firebase/config'
import { LanguageGlobal, useLanguageGlobal} from '../composables/useLanguageGlobal'
import {useSelectedProject} from '../composables/useSelectedProject'
import { useNewDocument }  from '../composables/useNewDocument';
import ChatCompletion from '../composables/ChatCompletion'

export default {
  props: ["chatInput", "sendChat"],

    setup(props, context) {
        const { LanguageGlobal, updateLanguageGlobal } = useLanguageGlobal()
        const { selectedGlobalProject, updateSelectedGlobalProject } = useSelectedProject()
        const { createNewDocument } = useNewDocument();
        const {user} = getUser()
        const show = ref('')
        const input = ref('')
        const output = ref('')
        const conversation = ref('')
        const showText = ref('')
        const loading = ref('')
        const prePhrases = ref(true)
        const localDocuments = ref([])
        const { error, documents } = getCollection('chat', ["userId", "==", user.value.uid] )
        const oldMessagesSet = ref(false)
        const messageHistoryCount = ref(15)

      // Get current date
        const date = new Date()
        // Format the date in a human readable way
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        })
        const messages = ref([{"role": "system", "content": 'You are Sunny an AI that answers as concisely as possible and you talk to a person called ' + user.displayName +'. You are an expert in a variety of topics but you dont give advice in critical topics such as politics, mental health or religion. You are embedded into an AI writing tool called Fuse that helps to organise notes and ideas in order to bring them into a coherent story. Your knowledge cutoff is in June 2021 and today is: ' + formattedDate }])

        const chatPromptEN = ref('The following is a conversation between an AI called Sunny and a person called ' + user.displayName +'. The AI is an expert in a variety of topics and only answers the truth. It is very friendly and helpful and does not give adice in critical topics such as politics, mental health or religion. It is embedded into an AI writing tool called Fuse and helps the writer to bring raw ideas into a coherent story and overcome their mental blocks. Sunny is very helpful and tries to help the person research or write basic texts. Its knowledge cutoff is in June 2021. And today is: ' + formattedDate + '\n\n' )
        // + 'The last conversations they had were about architecture in outer space and they were discussing how hard it was to build on mars. They were discussing possible solutions like 3D Printing shelter with dust on mars. The conversation before that was about how AI could help people in their creative blocks. They were discussing how AI could help people to find inspiration and how AI could help people to find the right words to express their ideas\n\n')
        const chatPromptDE = ref('Die folgende Unterhaltung ist zwischen einem Menschen und einer künstlichen intelligenten Assistenz namens Sunny. Sunny ist Experte in vielen Themen und erzählt immer die Wahrheit. Es ist sehr freundlich und hilfsbereit und gibt keine Ratschläge in kritischen Themen wie Politik, Gesundheit oder Religion. Sunny ist in ein KI-Schreibtool namens Fuse integriert. Das Wissen von Sunny endet im Juni 2021 und heute ist: ' + formattedDate + '\n\n')


        const chatInput = computed(() => {
            //console.log('chatInput: ' + props.chatInput)
            return props.chatInput;
        });

        const sendChat = computed(() => {
            //console.log('sendChat: ' + props.sendChat)
            return props.sendChat;
        });

        watch(sendChat, (newValue) => {
            console.log('sendChat changed: ' + newValue)
            complete(chatInput.value)
        })

        onMounted(() => {

            console.log('Chat mounted')
            //If the global language is set to English, show the English chat
            if (LanguageGlobal.value == 'English') {
                conversation.value = chatPromptEN.value
            }
            else if (LanguageGlobal.value == 'Deutsch') {
              conversation.value = chatPromptDE.value
            }
            checkNewUser()

            if (chatInput.value != '') {
                complete(chatInput.value)
            }


        })

        watch(LanguageGlobal, (newValue) => {
          
            if (newValue == 'English') {
                conversation.value = chatPromptEN.value
            }
            else if (newValue == 'Deutsch') {
              conversation.value = chatPromptDE.value
            }
        })

        const checkNewUser = async () => {
            const userRef = doc(db, 'users', user.value.uid);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                
                if (docSnap.data().usageCount < 500) {
                    complete('Hey what can I do here?')
                }
            } else {
                console.log('No such document!');
            }
        }

        const handleNewDocumentClick = async (input) => {

          let content = '<h1></h1><p>' + input + '</p>'
            await createNewDocument('', content, '', '', '');
            };


        const formattedDocuments = computed(() => {
        if (documents.value) {
         
          let filtered = documents.value.filter(doc => doc.docId == '')

          filtered.sort((a, b) => a.createdAt - b.createdAt)

          // Only show the amount of messages that is set in the messageHistoryCount variable
          // if (filtered.length > messageHistoryCount.value) {
          //   filtered = filtered.slice(filtered.length - messageHistoryCount.value, filtered.length)
          // }

          // Show only the lasdt 30 messages
          if (filtered.length > messageHistoryCount.value) {
            filtered = filtered.slice(filtered.length - messageHistoryCount.value, filtered.length)
          }

          return filtered.map(doc => {
            let time = formatDistanceToNow(doc.createdAt.toDate())
            return { ...doc, createdAt: time }
          })
          
      }
    })

    watch(formattedDocuments, (newValue) => {
      
            localDocuments.value = newValue
        })

        watch (localDocuments, (newValue) => {
            console.log('localDocuments changed')
            if (newValue.length > 0 && oldMessagesSet.value == false) {
                            // Add the last 10 messages to the messages array and if it is from the AI role, add the AI role to the message
            localDocuments.value.forEach(doc => {
                if (doc.role == 'assistant') {
                    messages.value.push({"role": "assistant", "content": doc.message})
                }
                else if (doc.role == 'user') {
                    messages.value.push({"role": "user", "content": doc.message})
                }
            })

          // Log every message in the messages array to the console
            messages.value.forEach(message => {
              console.log(message.role + ': ' + message.content)
            })

            oldMessagesSet.value = true
            }
        })


        const complete = async (input) => {

       // Add role: user and content: i to the messages array

        messages.value.push({role: 'user', content: input})

        const colRef = collection(db, 'chat')
        if (input != '') {
          await addDoc(colRef, {
              name: user.value.displayName,
              role: 'user',
              userId: user.value.uid,
              message: input,
              createdAt: serverTimestamp(),
              docId: ''
          })
          if (!error.value) {
            //chatInput.value = ''
          }
        }

        loading.value  = true;

        // Log every message in the messages array to the console
        messages.value.forEach(message => {
          console.log(message.role + ': ' + message.content)
        })

        const output = await ChatCompletion(messages.value)

        messages.value.push({role: output.completion.role, content: output.completion.content})
        
        loading.value  = false;

        await addDoc(colRef, {
            name: 'AI',
            role: output.completion.role,
            userId: user.value.uid,
            message: output.completion.content,
            createdAt: serverTimestamp(),
            docId: ''
        })

        }

// Router initialization

        const router = useRouter()
        const route = useRoute()

//auto-scroll
        const scrollMessages = ref(null)

        onUpdated(() => {
          scrollMessages.value.scrollTop = scrollMessages.value.scrollHeight
        })

        const showMoreMessages = (input) => {
          messageHistoryCount.value = messageHistoryCount.value + input
          
        }



        return {showMoreMessages, oldMessagesSet, handleNewDocumentClick, show, input, complete, output, conversation, showText, loading, user, prePhrases, documents, formattedDocuments, error, scrollMessages, localDocuments}
    },

      methods: {
      redirectTo (pathname) {
        this.$router.push(
            {
              path: '/' + pathname,
            }
     )
 }

  }

}
</script>

<style scoped>


.loader {
  /* the colors */
  --c1: #F77825;
  --c2: #D3CE3D;
  --c3: #60B99A;
  --c4: #554236;
  /**/
  
  width: 180px; /* control the size */
  aspect-ratio: 8/5;
  --_g: no-repeat radial-gradient(#000 68%,#0000 71%);
  -webkit-mask: var(--_g),var(--_g),var(--_g);
  -webkit-mask-size: 25% 40%;
  background: 
    conic-gradient(var(--c1) 50%,var(--c2) 0) no-repeat,
    conic-gradient(var(--c3) 50%,var(--c4) 0) no-repeat;
  background-size: 200% 50%;
  animation: 
    back 2.5s   infinite steps(1),
    load 1.5s infinite linear;
}

@keyframes load {
  0%   {-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 0%  }
  12.5%{-webkit-mask-position: 0% 50% ,50% 0%  ,100% 0%  }
  25%  {-webkit-mask-position: 0% 100%,50% 50% ,100% 0%  }
  37.5%{-webkit-mask-position: 0% 100%,50% 100%,100% 50% }
  50%  {-webkit-mask-position: 0% 100%,50% 100%,100% 100%}
  62.5%{-webkit-mask-position: 0% 50% ,50% 100%,100% 100%}
  75%  {-webkit-mask-position: 0% 0%  ,50% 50% ,100% 100%}
  87.5%{-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 50% }
  100% {-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 0%  }
}
@keyframes back {
  0%,
  100%{background-position: 0%   0%,0%   100%}
  25% {background-position: 100% 0%,0%   100%}
  50% {background-position: 100% 0%,100% 100%}
  75% {background-position: 0%   0%,100% 100%}
}



body {
  margin:0;
  min-height:100vh;
  display:grid;
  place-content:center;
  align-items:center;
  grid-auto-flow:column;
  gap:50px;
}


</style>

