<template>
    <div class="fixed bottom-0  w-2/3 md:w-1/6 flex flex-col justify-between z-90 bg-neutral-300 dark:bg-neutral-700 rounded-tr-xl">
        <div class="flex justify-center items-center m-2">
            <div v-html="Text" class="text-neutral-800 text-xl m-2 mb-0"></div>
        </div>

        <div class="flex ml-2">
            <div @click="$emit('Accept')" class="btn pill hover:bg-neutral-200 mb-1">
                Accept
            </div> 
        
            <div @click="$emit('Decline')" class="btn pill hover:bg-neutral-200 mb-1">
                Decline
            </div>
        </div>
    
    </div>
    </template>
    
    <script>
    
    import { computed, ref, watch } from 'vue'
    
    export default {
        name: 'Cookies',
        emits: ['Accept', 'Decline'],
        setup () {
            const Text = ref('<p>This website uses cookies to ensure you get the best experience on our website. <a class="text-sm font-medium" href="https://fuse.ooo/privacy">Learn more</a></p>') 
            const MoreInfo = ref('More Info')
            const Accept = () => { }
            const Decline = () => { }


            return {
                Text,
                Accept,
                Decline,
                MoreInfo
            }
        }
    
    }
    </script>
    
    <style>
    
    </style>