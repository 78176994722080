const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  apiKey: process.env.VUE_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);


const ChatCompletion = async (messages) => {

let completion = await openai.createChatCompletion({
  model: "gpt-3.5-turbo",
  messages: messages,
});
console.log(completion.data.choices[0].message);
completion = completion.data.choices[0].message

return  {completion} ;
    
}

export default ChatCompletion
