import { ref } from 'vue'
import { auth, db } from '../firebase/config'
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore"; 


const error = ref(null)
const isPending = ref(false)

const signup = async (email, password, displayName) => {
  error.value = null
  isPending.value = true

  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    if (!res) {
      throw new Error('Could not complete Signup')
    }
    await updateProfile(auth.currentUser, { displayName })
//    res.user.updateProfile({ displayName })
    error.value = null
    isPending.value = false

    
    await setDoc(doc(db, "users", (res.user.uid)), {
      displayName: displayName,
      email: email,
      createdAt: serverTimestamp(),
      editedAt: serverTimestamp(),
      usageCredits: 100000,
      usageCount: 0,
      userId: res.user.uid,

    });

    return res
  }
  catch(err) {
    console.log(err.message)
    error.value = err.message
    isPending.value = false
  }
}

const useSignup = () => {
  return { error, signup, isPending }
}

export default useSignup