<template>

<div class="bg-neutral-125 dark:bg-zinc-800 flex flex-col justify-between w-full h-full rounded-2xl">

  <div class="w-full sticky z-90 flex flex-row rounded-t-2xl bg-neutral-200 dark:bg-zinc-800 p-1 ">
  <div class="h-8 p-2"></div>
  
</div>   

  <div class="flex-col w-full h-full justify-center rounded-t-2xl">
      <div v-if="error">{{ error }}</div>

      <div class="overflow-scroll h-screen75 p-4 mt-8 scroll-smooth" ref="messages">

        <div v-for="doc in localDocuments" :key="doc.id" class="chatBubble">

          <div v-if="doc.name == 'AI'" class="flex flex-row">
            <p class="h2  text-neutral-400">Sunny</p>
          </div>
          <div v-if="doc.name != 'AI'" class="flex flex-row">
            <p class="h2 text-neutral-400">{{ doc.name }}</p>
          </div>
          <p class="p pt-1 text-md break-normal whitespace-pre-line">{{ doc.message }}</p>

        </div>

      </div>

      <div>
  <div class="flex justify-center w-full relative">

      <div v-if="loading"><div class="loader absolute scale-100 bottom-14 left-3" style="width:30px;--c1:#FFFFFF;--c2:#FFFFFF;--c3:#FFFFFF;--c4:#FFFFFF"></div></div>

      <textarea  class="w-full p-2 resize-none rounded-b-2xl bg-neutral-200 dark:bg-zinc-800 shadow-2xl h-10 pl-4" v-model="input" type="text" placeholder="Ask something or just chat about an idea" @keypress.enter.prevent="complete(input)"></textarea>

      <div @click.prevent="complete(input)" class="absolute z-80 btn right-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#b9b9b9" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
          </svg>
      </div>

  </div>
</div>
      
  </div>



</div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import Completion from '../composables/Completion'
import getUser from '../composables/getUser'

import useCollection from '../composables/useCollection'
import { collection, addDoc, serverTimestamp, deleteDoc, updateDoc, doc, getDoc} from '@firebase/firestore'


import getCollection from '../composables/getCollection'
import { computed, onMounted, onUpdated, ref, watch } from 'vue'
import { formatDistanceToNow } from 'date-fns'
import { db } from '@/firebase/config'
import { LanguageGlobal, useLanguageGlobal} from '../composables/useLanguageGlobal'
import {useSelectedProject} from '../composables/useSelectedProject'

export default {


    setup() {
        const { LanguageGlobal, updateLanguageGlobal } = useLanguageGlobal()
        const { selectedGlobalProject, updateSelectedGlobalProject } = useSelectedProject()

      // Get current date
        const date = new Date()
        // Format the date in a human readable way
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        })

        const show = ref('')
        const input = ref('')
        const output = ref('')
        const conversation = ref('')
        const chatGPT = ref('The following is a conversation between a human and an AI assistant called ChatGPT. Today is December 26, 2022. ChatGPTs knowledge cutoff is 2021-09, and it is not aware of any events after that time. ChatGPT will refuse requests related to violence, sexuality, self-harm, or requests to produce offensive or highly controversial material. It will remain diplomatic and neutral on all sensitive questions, and avoid saying anything that might offend. When code examples or long documents are requests, ChatGPT will provide them using Markdown syntax. Example transcript follows.\n\nHuman: Hello! Who are you?\nChatGPT: Hi there! I am ChatGPT, an AI assistant. I can help you with things like answeringquestions, providing information, and helping with tasks. How can I be of assistance?')
        const showText = ref('')
        const loading = ref('')
        const chatPromptEN = ref('The following is a conversation between an AI called Sunny and a person. The AI is an expert in a variety of topics and only answers the truth. It is very friendly and helpful and does not give adice in critical topics such as politics, mental health or religion. It is embedded into an AI writing tool called Fuse and helps the writer to bring raw ideas into a coherent story and also answer questions about how to use the software. For example to trigger Autocompletions the writer just has to type three dots (...) And to ignore the Autocompletion the writer can just continue typing and to accept it the writer should press Enter. When the writer is selecting text there are more options like rephrasing and translation. Its knowledge cutoff is in June 2021. And today is: ' + formattedDate + '\n\n')
        const chatPromptDE = ref('Die folgende Unterhaltung ist zwischen einem Menschen und einer künstlichen intelligenten Assistenz namens Sunny. Sunny ist Experte in vielen Themen und erzählt immer die Wahrheit. Es ist sehr freundlich und hilfsbereit und gibt keine Ratschläge in kritischen Themen wie Politik, Gesundheit oder Religion. Sunny ist in ein KI-Schreibtool namens Fuse integriert und hilft Autoren erste Ideen in flüssige Geschichten zu bringen. Es kann auch Fragen über die Bedienung der Software beantworten. Zum Beispiel um Autocomplete zu starten müssen die Nutzerinnen drei Punkte (...) eingeben. Und zum annehmen Enter drücken. Wenn man Text auswählt kann man zusätzliche Funktionen wie Umformulieren oder Zusammenfassen finden. Das Wissen von Sunny endet im Juni 2021 und heute ist: ' + formattedDate + '\n\n')

        const prePhrases = ref(true)
        const localDocuments = ref([])
        const {user} = getUser()
        //const { error, documents } = getCollection('chat', ['userId', '==', user.value.uid])

        // Only get the collection of chat where createdAt is smaller than 20 minutes ago
        const { error, documents } = getCollection('chat' )
        //['createdAt', '>', new Date(Date.now() - 20 * 60 * 1000)]

        onMounted(() => {


            //console.log('JSON formatteddocuments.value: ' + JSON.stringify(formattedDocuments.value, null, 4));
            console.log('Chat mounted')
            //If the global language is set to English, show the English chat
            if (LanguageGlobal.value == 'English') {
                conversation.value = chatPromptEN.value
            }
            else if (LanguageGlobal.value == 'Deutsch') {
              conversation.value = chatPromptDE.value
            }
            checkNewUser()
        })

        watch(LanguageGlobal, (newValue) => {
          
            if (newValue == 'English') {
                conversation.value = chatPromptEN.value
            }
            else if (newValue == 'Deutsch') {
              conversation.value = chatPromptDE.value
            }
        })

        const checkNewUser = async () => {
            const userRef = doc(db, 'users', user.value.uid);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                
                if (docSnap.data().usageCount < 500) {
                    complete('Hey what can I do here?')
                }
            } else {
                // doc.data() will be undefined in this case
                console.log('No such document!');
            }
        }


        

// format timestamp

        const formattedDocuments = computed(() => {
        if (documents.value) {

          // Only get documents from the last 10 minutes
          // let now = new Date()
          // let tenMinutesAgo = new Date(now.getTime() - 20 * 60 * 1000)
          // let filtered = documents.value.filter(doc => doc.createdAt.toDate() > tenMinutesAgo)

          // Filter out documents where docId is not the same as the selected project
          let filtered = documents.value.filter(doc => doc.docId == selectedGlobalProject.value.id)
          filtered.sort((a, b) => a.createdAt - b.createdAt)
          
          return filtered.map(doc => {
            let time = formatDistanceToNow(doc.createdAt.toDate())
            return { ...doc, createdAt: time }
          })
          
          
          return documents.value.sort((a, b) => a.createdAt - b.createdAt)
           return documents.value.map(doc => {
          let time = formatDistanceToNow(doc.createdAt.toDate())
          return { ...doc, createdAt: time }
        })
      }
    })

    watch(formattedDocuments, (newValue) => {
      
            localDocuments.value = newValue
        })

// OpenAI completion

        const complete = async (i) => {

            const prompt = conversation.value + user.value.displayName + ": " + i + "\nAI: "

            const colRef = collection(db, 'chat')
            if (input.value != '') {
              await addDoc(colRef, {
                  name: user.value.displayName,
                  userId: user.value.uid,
                  message: input.value,
                  createdAt: serverTimestamp(),
                  docId: selectedGlobalProject.value.id
              })
              if (!error.value) {
                  input.value = ''
              }
            }

            prePhrases.value = false;

            loading.value  = true;  
        
            const stop1 = 'Human: '
            console.log('chatPrompt: ' + prompt)
            
            const output = await Completion(prompt, "text-davinci-003", 300 , 'AI: ', '', 0.8, 0.5, 0.6, 1 )

            conversation.value = prompt + output.completion

            loading.value  = false;
            
            console.log(output.completion)

            await addDoc(colRef, {
                name: 'AI',
                userId: user.value.uid,
                message: output.completion,
                createdAt: serverTimestamp(),
                docId: selectedGlobalProject.value.id
            })

        }

// Router initialization

        const router = useRouter()
        const route = useRoute()

//auto-scroll
        const messages = ref(null)

        onUpdated(() => {
           messages.value.scrollTop = messages.value.scrollHeight
        })



        return {show, input, complete, output, conversation, showText, loading, user, prePhrases, documents, formattedDocuments, error, messages, localDocuments}
    },

      methods: {
      redirectTo (pathname) {
        this.$router.push(
            {
              path: '/' + pathname,
            }
     )
 }

  }

}
</script>

<style scoped>


.loader {
  /* the colors */
  --c1: #F77825;
  --c2: #D3CE3D;
  --c3: #60B99A;
  --c4: #554236;
  /**/
  
  width: 180px; /* control the size */
  aspect-ratio: 8/5;
  --_g: no-repeat radial-gradient(#000 68%,#0000 71%);
  -webkit-mask: var(--_g),var(--_g),var(--_g);
  -webkit-mask-size: 25% 40%;
  background: 
    conic-gradient(var(--c1) 50%,var(--c2) 0) no-repeat,
    conic-gradient(var(--c3) 50%,var(--c4) 0) no-repeat;
  background-size: 200% 50%;
  animation: 
    back 3s   infinite steps(1),
    load 1.5s infinite linear;
}

@keyframes load {
  0%   {-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 0%  }
  12.5%{-webkit-mask-position: 0% 50% ,50% 0%  ,100% 0%  }
  25%  {-webkit-mask-position: 0% 100%,50% 50% ,100% 0%  }
  37.5%{-webkit-mask-position: 0% 100%,50% 100%,100% 50% }
  50%  {-webkit-mask-position: 0% 100%,50% 100%,100% 100%}
  62.5%{-webkit-mask-position: 0% 50% ,50% 100%,100% 100%}
  75%  {-webkit-mask-position: 0% 0%  ,50% 50% ,100% 100%}
  87.5%{-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 50% }
  100% {-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 0%  }
}
@keyframes back {
  0%,
  100%{background-position: 0%   0%,0%   100%}
  25% {background-position: 100% 0%,0%   100%}
  50% {background-position: 100% 0%,100% 100%}
  75% {background-position: 0%   0%,100% 100%}
}



body {
  margin:0;
  min-height:100vh;
  display:grid;
  place-content:center;
  align-items:center;
  grid-auto-flow:column;
  gap:50px;
}


</style>

