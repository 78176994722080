<template>

<div class="w-full" style="scrollbar-width: none">
  
  <div class="w-full sticky z-90 flex flex-row rounded-t-2xl bg-neutral-200 dark:bg-zinc-800 p-1 pb-0">

    <div @click="closeEditor()"  class="btn z-20 ml-1 w-10 h-10 rounded-full  opacity-40 hover:opacity-100">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 opacity-40 hover:opacity-80 svgFill" viewBox="0 0 24 24"><path  d="M18 12.998H6a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z"/></svg> -->
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"  class="w-8 h-8 opacity-40 hover:opacity-80 svgStroke">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>

    <div class="ml-4 flex left-2 rounded-full   opacity-60">
      <div class="tooltip-button btn w-10 h-10 float-left opacity-30">
          <span class="tooltip-text">Audio (soon)</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.9" stroke="currentColor" class="w-4 h-4 svgStroke">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
          </svg>
      </div>

    <button class="btn opacity-40 hover:opacity-100"
      @click="undo = !undo">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-4 svgStroke">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
      </svg>
    </button>

    <button class="btn opacity-40 hover:opacity-100"
      @click="redo = !redo">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-4 svgStroke">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
      </svg>
    </button>

    <button class="btn opacity-40 hover:opacity-100"
      @click="changeFont = !changeFont">
      <svg class="w-5 h-5 svgFill" viewBox="0 0 16 16"><path  d="M12.258 3h-8.51l-.083 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.431.013c1.934.062 2.434.301 2.693 1.846h.479L12.258 3z"/></svg>    
    </button>
  </div>


  <div @click="updateLanguageGlobal('Deutsch')" v-if="LanguageGlobal == 'English'" class="btn z-20 mt-1 ml-1 w-8 h-8 rounded-full  opacity-30 hover:opacity-100">
    <div class="text-xs w-4 h-4 m-auto text-neutral-800 dark:text-zinc-400">EN</div>
  </div>

  <div @click="updateLanguageGlobal('English')" v-if="LanguageGlobal == 'Deutsch'" class="btn z-20 ml-1 w-8 h-8 rounded-full  opacity-30 hover:opacity-100">
    <div class="text-xs m-auto text-neutral-800 dark:text-zinc-400" >DE</div>
  </div>

  <div  class="btn z-20 ml-1 w-8 h-8 rounded-full  opacity-80">

   <div class="w-2 h-2 mt-2 transition-all rounded-full duration-500 ease" :class="{'bg-neutral-300': !synced, 'bg-emerald-500': synced}" ></div>

  </div>


  <!-- <div class="ml-4 flex left-5 rounded-full bg-neutral-300 opacity-40 hover:opacity-100">
    <div class="text-neutral-900 mx-4 m-auto">Tags</div>
  </div> -->

  <div @click="copyToClipboard()"  class="btn z-20 ml-1 w-8 h-8 rounded-full  opacity-40 hover:opacity-100 btn flex absolute right-20 ">
    <button class="btn tooltip-button opacity-40 hover:opacity-80">

      <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 svgStroke">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15" />
      </svg> -->

      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 " stroke-width="1" viewBox="0 0 24 24"><path fill="currentColor" d="M12 16q-.425 0-.712-.288Q11 15.425 11 15V4.825l-.9.9Q9.825 6 9.413 6Q9 6 8.7 5.7q-.275-.3-.275-.713q0-.412.275-.687l2.6-2.6q.125-.125.313-.2q.187-.075.387-.075t.388.075q.187.075.312.2l2.6 2.6q.3.3.3.725t-.3.7Q15 6 14.588 6q-.413 0-.688-.275l-.9-.9V15q0 .425-.287.712Q12.425 16 12 16Zm-6 7q-.825 0-1.412-.587Q4 21.825 4 21V10q0-.825.588-1.413Q5.175 8 6 8h2q.425 0 .713.287Q9 8.575 9 9t-.287.712Q8.425 10 8 10H6v11h12V10h-2q-.425 0-.712-.288Q15 9.425 15 9t.288-.713Q15.575 8 16 8h2q.825 0 1.413.587Q20 9.175 20 10v11q0 .825-.587 1.413Q18.825 23 18 23Z"/></svg>
      <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 svgFill" viewBox="0 0 24 24"><path  d="M5 22q-.825 0-1.413-.587Q3 20.825 3 20V6h2v14h11v2Zm4-4q-.825 0-1.412-.587Q7 16.825 7 16V4q0-.825.588-1.413Q8.175 2 9 2h9q.825 0 1.413.587Q20 3.175 20 4v12q0 .825-.587 1.413Q18.825 18 18 18Zm0-2h9V4H9v12Zm0 0V4v12Z"/></svg> -->
      <span class="tooltip-text shadow-xl">Copy</span>
    </button>
      
  </div>


    <div  @click="$emit('toggleFullScreen'), FullScreen = !FullScreen"  class="tooltip-button btn ml-4 h-8 w-8 flex absolute right-12 rounded-full  opacity-40 hover:opacity-100" >
         <div class=" transition-rotation duration-500 ease opacity-40 hover:opacity-80" :class="{'-rotate-180': FullScreen}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="black" class="w-4 h-4" ><path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" /></svg>
        </div>
        <span class="tooltip-text shadow-xl">Focus</span>
    </div>  

    <div @click="$emit('showChat')" class=" w-10 h-10 z-90 flex items-center justify-center btn absolute right-1 top-0 transition duration-500 ease rounded-full  " >
            <div v-if="!loading" class="rounded-full bg-neonyellow  dark:bg-gray-300 dark:border dark:border-blue-800 blur-[1px] hover:blur-0 w-5 h-5 opacity-80 hover:opacity-100 transition duration-200 ease scale-100 hover:scale-110"></div>
            <div v-if="loading" class="dots-6 scale-75 top-10 right-9 fixed"></div>
    </div>

  </div>

  <!-- <div class=" w-10 h-10 z-90 flex items-center justify-center btn absolute left-5 mr-1 bottom-14 transition duration-500 ease rounded-full bg-zinc-200 " >
    <div class="rounded-full bg-neonyellow border border-violet-100 dark:bg-gray-300 dark:border dark:border-blue-800 blur-[1px] hover:blur-0 bg-opacity-70 w-5 h-5 opacity-70 hover:opacity-100 transition duration-200 ease scale-100 hover:scale-110"></div>
  </div> -->

    <div class="relative h-full flex justify-center overflow-scroll scroll-smooth z-80">
      <TipTap class="autoComplete" 
      v-model="contentLocal" 
      :undo="undo"
      :redo="redo"
      :changeFont="changeFont"
      @update:cursorPosition="(n) => cursorPosition = n"  
      @TextBeforeCursor="(n) => TextBeforeCursor = n" 
      @TextAfterCursor="(n) => TextAfterCursor = n"
      @update:loading="(n) => loading = n"/>
    </div> 

</div>

</template>

<script>

import { ref, watch, onMounted, onBeforeUnmount, computed} from 'vue'
import { serverTimestamp, updateDoc, doc, getDoc} from '@firebase/firestore'
import { db } from '@/firebase/config'
import getUser from '../composables/getUser'
import {debounce} from 'debounce'
import Completion from '../composables/Completion'
import {useSelectedProject} from '../composables/useSelectedProject'
import {useLanguageGlobal} from '../composables/useLanguageGlobal'
import TipTap from '../components/TipTap.vue'
import router from '@/router'
import { useRouter } from 'vue-router'


export default {
    components: { TipTap },
    name: 'EditorModule',
    emits: [
      'toggleFullScreen',
      'closeEditor',
      'update:loading',
      'showChat'],

    setup(props, context) {
        const {user} = getUser()
        const { selectedGlobalProject, updateSelectedGlobalProject } = useSelectedProject()
        const { LanguageGlobal, updateLanguageGlobal } = useLanguageGlobal()
        const contentLocal = ref('')
        const titleLocal = ref('')
        const autoCompletion = ref('')
        const typedCharacters = ref(0)
        const FullScreen = ref(false)
        const ProjectId = ref('')
        const Prompt = ref('')
        const TextBeforeCursor = ref('')
        const TextAfterCursor = ref('')
        const undo = ref(false)
        const redo = ref(false)
        const changeFont = ref(false)
        const LanguageDetected = ref(false)
        const router = useRouter()
        const loading = ref(null)
        const synced = ref(null)
        const id = computed(() => router.currentRoute.value.params.id);

        watch(loading, (newValue) => {
          //emit an event to the parent
          context.emit('update:loading', newValue)
          console.log('loading:', newValue)
        })

        onMounted(async () => {
            console.log('Editor mounted')
            // Get the current route

            console.log('selectedGlobalProject.value in editor', selectedGlobalProject.value)

            if (selectedGlobalProject.value) {
              console.log('selectedGlobalProject.value.id', selectedGlobalProject.value.id)
            
            ProjectId.value = selectedGlobalProject.value.id
            contentLocal.value = selectedGlobalProject.value.content
            titleLocal.value = selectedGlobalProject.value.title
            }

            else {
                console.log('No project selected')
                // Get the project from the database
                const docSnap = await getDoc(doc(db, 'notes', id.value));
                const formattedDoc = formatDocument(docSnap);
                updateSelectedGlobalProject(formattedDoc)
                
            }
        })

        function formatDocument(docSnap) {
          return {
            ...docSnap.data(),
            id: docSnap.id,
          };
        }


        // watch(selectedGlobalProject, (newValue) => {

        //     if (newValue == null) {
        //       getProjectFromRoute()
        //     }
        // })

        // const getProjectFromRoute = async () => {
        //     // Get the current route
        //     const currentRoute = router.currentRoute.value.path
        //     // Slice the current route to get the ProjectId
        //     const routeId = currentRoute.slice(8)
        //     console.log('ProjectId:', ProjectId.value)
        //     // Get the project from the database
        //     const docSnap = await getDoc(doc(db, 'notes', routeId));
        //     updateSelectedGlobalProject(docSnap.data())
        // }

        watch(LanguageGlobal, (newValue) => {
            console.log('Language changed:', newValue)
            if (newValue == 'Deutsch') {
            Prompt.value = 'Der folgende Text wurde von einer sehr intelligenten und gebildeten Person geschrieben. Die Person ist sehr gut darin Geschichten zu schreiben und begeistert sich für kritisches und reflektiertes Denken.\n\n'
            console.log('Prompt ist auf Deutsch')
            }
            else if (newValue == 'English') {
            Prompt.value = 'The following text was written by a very intelligent and educated person. The person is very good at writing stories and is passionate about critical and reflective thinking.\n\n'
            console.log('Prompt set to English')
            }
        })

        // Watch selectedGlobalProject for changes and update the local content and title when the story changes

        watch (selectedGlobalProject, (newValue) => {
            if (newValue) {
              console.log('selectedGlobalProject in Editor changed')

                ProjectId.value = selectedGlobalProject.value.Id
                contentLocal.value = selectedGlobalProject.value.content
                titleLocal.value = selectedGlobalProject.value.title
                autoCompletion.value = ''
            }
            else if (newValue == null) {
                router.push('/')
            }
          
          }) 

          // Watch the local content and title for changes and save with debounce

          watch (contentLocal, (newValue) => {
            if (newValue) {
              synced.value = false
            debounceContent()
            if (contentLocal.value.length > 10) {
                //detectLanguage()
                }
            }})
        

      // Detect the Language of the Text
      
      const detectLanguage = async () => {
        if (LanguageDetected.value == false) {

          let prompt = contentLocal.value.slice(0, 100) + "\n\nWhat language is the text above? Answer in one Word\n\n" 
          const response = await Completion(prompt, "text-davinci-003", 15, ' ');
          
          if (response.completion == "German" || response.completion == "Deutsch" || response.completion == "german" || response.completion == "DE" || response.completion == "De" || response.completion == "de") {
            updateLanguageGlobal("Deutsch")   
            console.log("Language set to: " + LanguageGlobal.value)  
            LanguageDetected.value = true 
          }    
          else if (response.completion == "English" || response.completion == "english" || response.completion == "En" || response.completion == "en" || response.completion == "Englisch" || response.completion == "English.") {
            updateLanguageGlobal("English")   
            console.log("Language set to: " + LanguageGlobal.value)
            LanguageDetected.value = true 
          }         
      }}

      // Update Document Content

        const updateContent = async () => {           
          try {    
            synced.value = false
            //console.log('Sync Content...') 
            await updateDoc(doc(db, 'notes', selectedGlobalProject.value.id), {
                content: contentLocal.value,
                editedAt: serverTimestamp()
            })
            //console.log('Content Synced')   
            synced.value = true
            
          }
           catch (err) {
                console.log(err.message)
            }                
        }

        const closeEditor = async () => {           
          try {    
            //console.log('Sync Content...') 
            console.log('selectedGlobalProject.value.id', selectedGlobalProject.value.id)
            await updateDoc(doc(db, 'notes', selectedGlobalProject.value.id), {
                content: contentLocal.value,
                editedAt: serverTimestamp()
            })
            //console.log('Content Synced')   
            context.emit('closeEditor')
          }
           catch (err) {
                console.log(err.message)
                context.emit('closeEditor')
            }                
        }

        const copyToClipboard = async () => {
            try {
              // Get the plain text content from the HTML of contententlocal
              const content = contentLocal.value
              const div = document.createElement('div')
              div.innerHTML = content
              const contentText = div.textContent || div.innerText || ''

                await navigator.clipboard.writeText(contentText)
                console.log('Text copied to clipboard')
            }
            catch (err) {
                console.log(err.message)
            }
        }
        

        // Debounce Content Update

        const debounceContent = debounce(() => {
          updateContent()
        }, 1000)


        // Save contentlocal before unmount
        onBeforeUnmount(async() => {
          await updateContent()          
        })


        return { 
        updateContent,
        contentLocal,
        titleLocal,
        typedCharacters,
        FullScreen,
        Prompt,
        TextBeforeCursor,
        TextAfterCursor,
        undo,
        redo,
        changeFont,
        closeEditor,
        copyToClipboard,
        LanguageGlobal,
        updateLanguageGlobal,
        loading,
        synced

         }
    }

}
</script>

<style >



</style>

