<template>

<div class="flex-col justify-center items-center w-full">


<Transition>

<div v-if="localDocuments" style="scrollbar-width: none"  class="overflow-scroll bg-opacity-0 p-2">    
     
        <TransitionGroup name="list" tag="ul" class="m-auto" :class="{ gridLayout: ProjectsLayout == 'Grid', listLayout: ProjectsLayout == 'List'}">
                
                <div v-for="element in localDocuments" :key="element.id" >
                <router-link :to="{ name: 'Editor', params: {id: element.id}}">
                <div @click="selectProject(element)" class="group relative overflow-hidden transition-width-height duration-500 ease" :class="{project: element.id != selectedProjectId, selectedProject: element.id == selectedProjectId, gridBox: ProjectsLayout == 'Grid', listBox: ProjectsLayout == 'List'}">
                    <div @click.stop.prevent="handleDelete(element)" class="float-right w-8 h-8 invisible group-hover:visible btn btnbg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="grey" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>

                    <!-- If element.title is empty display 'Untitled' but in grey instead of black -->
                    <!-- <h2 class="h2 line-clamp-2 " :class="{'opacity-50': element.title == ''}"> {{ element.title || 'Untitled' }}</h2>     -->
                    <div v-html="element.content"  class="text-md pt-1  " :class="{'line-clamp-5': ProjectsLayout == 'Grid', 'line-clamp-3': ProjectsLayout == 'List', 'opacity-30': element.content == ''}"></div>
                    <div class="text-gray-400 bottom-1 mt-2 truncate text-ellipsis overflow-hidden"> {{ element.editedAt }} ago</div>        
                </div>
            </router-link>
            </div>
        </TransitionGroup>
</div>   
</Transition>     

</div>

</template>

<script>

import { computed, onBeforeMount, onMounted, onUpdated, ref, Transition, watch } from 'vue'
import getCollection from '@/composables/getCollection.js'
import { formatDistanceToNow } from 'date-fns'
import { collection, addDoc, serverTimestamp, deleteDoc, updateDoc, doc, getDoc, onSnapshot} from '@firebase/firestore'
import { db } from '@/firebase/config'
import getUser from '../composables/getUser'
import {useSelectedProject} from '../composables/useSelectedProject'
import { useNewDocument }  from '../composables/useNewDocument';

export default {
    props: ["newProject", "ProjectsLayout", "query", "noLocalDocuments", "showAllDocuments"],
    setup(props, context) {
        const { user } = getUser();
        const { error, documents } = getCollection("notes", ["userId", "==", user.value.uid]);
        const { selectedGlobalProject, updateSelectedGlobalProject } = useSelectedProject();
        const { createNewDocument } = useNewDocument();
        const Projects = ref([]);
        const selectedProjectId = ref("");
        const localDocuments = ref([]);
        
        onMounted(() => {
            console.log("Projects mounted");
        });
        const showAllDocuments = computed(() => {
            return props.showAllDocuments;
        });
        const ProjectsLayout = computed(() => {
            return props.ProjectsLayout;
        });
        const createNewProject = computed(() => {
            return props.newProject;
        });
        const query = computed(() => {
            return props.query;
        });
        watch(createNewProject, (newValue) => {
            handleNewDocumentClick();
            //newProject();
        });
        // Format Timestamp
        const formattedDocuments = computed(() => {
            if (documents.value) {
                return documents.value.map(doc => {
                    let time = formatDistanceToNow(doc.editedAt.toDate());
                    // Include query so it updates when the query changes
                    query.value;
                    showAllDocuments.value;
                    return { ...doc, editedAt: time };
                });
            }
        });
        watch(formattedDocuments, (newValue) => {
            // If formattedDocuments is empty, open a new project
            if (newValue.length == 0) {
                //newProject();

                // emit noLocalDocuments to parent
                context.emit('noLocalDocuments', true);
            }
            if (query.value != "" && showAllDocuments.value == false) {
                context.emit('noLocalDocuments', false);
                // Only show local documents that contain the query
                localDocuments.value = newValue.filter(doc => {
                    return doc.content.toLowerCase().includes(query.value.toLowerCase());
                });
            }
            else if (query.value == "" && showAllDocuments.value == false) {
                localDocuments.value = null;
                context.emit('noLocalDocuments', true);
            }
            else if (query.value == "" && showAllDocuments.value == true) {
                localDocuments.value = newValue;
                context.emit('noLocalDocuments', false);
            }
            //localDocuments.value = newValue
        });

        const handleNewDocumentClick = async () => {
            await createNewDocument('', '', '', '', '');
            selectedProjectId.value = selectedGlobalProject.value.id;
            };



        // Add New Project
        const newProject = async () => {
            const colRef = collection(db, "notes");
            const docRef = await addDoc(colRef, {
                author: user.value.displayName,
                userId: user.value.uid,
                title: "",
                content: "",
                prompt: "",
                summary: "",
                createdAt: serverTimestamp(),
                editedAt: serverTimestamp(),
                isPinned: false,
                selected: false,
                tags: ["", "", ""],
            });
            selectedProjectId.value = docRef.id;
            // Get document with ID docRef.id and select it
            const docSnap = await getDoc(doc(db, "notes", docRef.id));
            selectProject(docSnap);
        };
        const selectProject = (element) => {
            updateSelectedGlobalProject(element);
        };

        // Delete Document
        const handleDelete = async (i) => {
            const colRef = collection(db, "deletedNotes");
            await addDoc(colRef, i);
            await deleteDoc(doc(db, "notes", i.id));
        };
        return {
            user,
            Projects,
            error,
            documents,
            formattedDocuments,
            newProject,
            handleDelete,
            selectProject,
            selectedProjectId,
            localDocuments,
            ProjectsLayout,
            updateSelectedGlobalProject,
            showAllDocuments,
        };
    },
    components: { Transition }
}
</script>


<style scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1)
}

.list-enter-from {
  opacity: 50;
  transform: scale(0.1);
  /* transform: translateY(0px); */
}

.list-leave-to {
    transform: scale(0.1);
  opacity: 0;
  /* transform: translateY(0px); */
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

.project{
    @apply bg-neutral-200 shadow-4xl bg-opacity-70 dark:bg-zinc-800 rounded-lg cursor-pointer hover:border-opacity-40 border-[1px] border-opacity-0 border-zinc-400 transition ease-in-out duration-300 
} 

.selectedProject{
    @apply opacity-0 dark:bg-zinc-700 transition ease-in-out duration-500 rounded-lg
} 

.gridLayout {
    @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-6 gap-4 sm:gap-4
}

.listLayout {
    @apply grid grid-cols-1 rounded-3xl duration-700
}

.gridBox {
    @apply  rounded-xl cursor-pointer p-4 h-52 
}

.listBox {
    @apply  p-3 w-full h-36 cursor-pointer my-2 relative bg-opacity-0 border-0 hover:bg-opacity-70 bg-zinc-300
}

.listInput {
    @apply w-96
}

.gridInput {
    @apply w-1/2
}

</style>