<template>
<div class="flex justify-center h-full bg-gradient-to-t from-neutral-200  to-neutral-50  overflow-scroll">
    <div class="flex-col m-auto w-full ">

        <div class="flex justify-center items-center">
            <div @click="router.go(-1)" class="text-neutral-800 text-xl m-2 cursor-pointer">Back</div>
        </div> 


        <div class="flex justify-center top-12 m-12">
            <div class="flex-col justify-center items-center w-1/2">
                <!-- <div class="text-neutral-800 text-4xl">Terms of Use<br><br></div> -->
                <div class="text-neutral-800 text-8xl">Privacy Policy</div>
                <div class="text-neutral-800 text-xl mt-12">
                  

                At Fuse, we take your privacy seriously. This privacy policy explains how we collect, use, and disclose your personal information through our app.
                <br><br>
                <p><strong>Information We Collect</strong></p>
                <br>

                When you use Fuse, we may collect the following types of information:

                - Information you provide: We may collect information you provide to us directly, such as your name, email address, and other contact information.
                - Log data: We may also collect log data when you use Fuse, such as your device type, IP address, and information about your use of the app.
                - OpenAI API: We use the OpenAI API to provide natural language processing functionality in the app, which may collect information about the text you input into the app. Find out more about OpenAIs Terms of use [here](https://openai.com/terms/).
                <br><br>
                <p><strong>Use of Information</strong></p>
                <br>

                We use the information we collect to provide and improve Fuse, as well as for the following purposes:

                - To provide customer support and troubleshoot issues
                - To improve the app and develop new features
                - To communicate with you about Fuse and send you updates
                <br><br>
                <p><strong>Sharing of Information</strong></p>
                <br>

                We may share your information with third parties for the following purposes:

                - With service providers: We may share your information with service providers who assist us in providing and improving Fuse, such as hosting and analytics providers.
                - With legal compliance: We may disclose your information to legal authorities if we believe it is necessary to comply with the law or to protect the rights and safety of Fuse and its users.
                <br><br>
                <p><strong>Security</strong></p>
                <br>

                We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security.

                Changes to this Privacy Policy

                We may update this privacy policy from time to time. If we make any material changes, we will notify you through the app or by email.
                <br><br>
                <p><strong>Contact Us</strong></p>
                <br>

                If you have any questions about this privacy policy, please contact us at hello@fuse.ooo

                Please note that this is just a sample and should be reviewed by a legal professional to ensure it meets the specific requirements of your app, company and jurisdiction. Also, please replace the contact information and any other specific information to match your app.
                    
                    </div>
            </div>
        </div>


         <!-- <div class="flex justify-center top-12 m-12">
            <div class="flex-col justify-center items-center w-1/2">
                <div class="text-neutral-800 text-8xl">Privacy Policy</div>
                <div class="text-neutral-800 text-xl mt-12">
                    Your privacy is important to us. It is our policy to respect your privacy regarding any information we may collect from you across our website https://fuse.ooo <br><br>

                    We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we are collecting it and how it will be used.

                    We only retain collected information for as long as necessary to provide you with your requested service. <br><br>What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.

                    We don’t share any personally identifying information publicly or with third-parties, except when required to by law.

                    Our website may link to external sites that are not operated by us. <br><br> Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.

                    You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of our desired services.

                    <br><br>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.

                    This policy is effective as of January 2023.
                    
                    </div>
            </div>
        </div> -->

        <!-- Cookie Policy -->
        <div class="flex justify-center top-12 m-12">
            <div class="flex-col justify-center items-center w-1/2">
                <h1 class="text-neutral-800 text-6xl">Cookie Policy</h1>

                    <p><br>This is the Cookie Policy for Fuse, accessible from https://fuse.ooo<br><br></p>

                    <p><strong>What Are Cookies<br></strong></p>

                    <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.</p>

                    <p><strong><br>How We Use Cookies<br></strong></p>

                    <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>

                    <p><strong><br>Disabling Cookies<br></strong></p>

                    <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies. This Cookies Policy was created with the help of the <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">Cookies Policy Generator</a>.</p>
                    <p><strong><br>The Cookies We Set<br></strong></p>

                    <ul>

                    <li>
                        <p>Account related cookies<br></p>
                        <p>If you create an account with us then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out however in some cases they may remain afterwards to remember your site preferences when logged out.</p>
                    </li>

                    <li>
                        <p>Login related cookies<br></p>
                        <p>We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</p>
                    </li>




                    <li>
                        <p>Forms related cookies<br></p>
                        <p>When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.</p>
                    </li>

                    <li>
                        <p>Site preferences cookies<br></p>
                        <p>In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</p>
                    </li>

                    </ul>

                    <p><strong><br>Third Party Cookies<br></strong></p>

                    <p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>

                    <ul>

                    <li>
                        <p>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.</p>
                        <p>For more information on Google Analytics cookies, see the official Google Analytics page.</p>
                    </li>

                    </ul>

                    <p><strong><br>More Information<br></strong></p>

                    <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.</p>

                    <p>However if you are still looking for more information then you can contact us through one of our preferred contact methods:</p>

                    <ul>
                    <li>Email: hello@fuse.ooo</li>

                    </ul>
        </div>
    </div>

         <div class="w-full h-12">
            <div class="flex-col flex justify-center items-center mt-6">          
                <img class="w-20" src="@/assets/Logo/tamelabs.png" alt="">
                <div class="m-1 text-xs">©2023</div>
            </div>
        </div>

    </div>
</div>


</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { ref } from 'vue'

export default {
    name: 'Privacy',
    setup() {
        const error = ref(null)
        const router = useRouter()


        return { 
            error,
            router
             }
    }
    }


</script>

<style>

</style>