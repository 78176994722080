<template>

  <!-- <div  class= "bg-gradient-to-t from-skyBeige via-skyGray to-skyPurple  fixed w-full h-screen dark:bg-transparent transition ease-out duration-700"> -->
    <!-- <div  class= "   fixed w-full h-screen dark:bg-transparent transition ease-out duration-700 bg-opacity-60" :class="backgroundClass"> -->
  <div>
    <div class= "bg-zinc-300 w-screen h-screen dark:bg-zinc-900 transition ease-out duration-700" >

    <!-- <button class="p-5 m-5 absolute rounded-full" :style="{ 'background-color': color}"></button> -->


    <router-view/>

    </div>
  </div>

</template>



<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import set from 'date-fns/set/index'


export default {

  name: 'App',
  setup() {

    const color = ref('')

    // Change the background of the app depending on the daytime

      const backgroundClass = computed(() => {
        const hour = new Date().getHours();
        if (hour >= 6 && hour < 12) {
          return 'morning';
        } else if (hour >= 12 && hour < 17) {
          return 'afternoon';
        } else if (hour >= 17 && hour < 22) {
          return 'evening';
        } else {
          return 'night';
        }
      })
    
    // onMounted(() => {
    //   console.log('App mounted')

    //   setInterval(() => {
    //     changeColor()
    //     console.log('color changed', color.value)
    //   }, 200)
      
    // })

    const changeColor = (input) => {
      let now = new Date()
      const hours = now.getHours()
      const minutes = now.getMinutes()
      const seconds = now.getSeconds()

      const red = Math.round((hours / 24) * 255)
      const green = Math.round((minutes / 60) * 255)
      const blue = Math.round((seconds / 60) * 255)

      color.value = `rgb(${red}, ${green}, ${blue})`
    }
    



    return { color, backgroundClass }
  }
}

</script>

<style >


.gradient {
  background: linear-gradient(90deg, #fff9f9 0%, #f0efff 100%);
}

.darkGradient {
  background: linear-gradient(90deg, #8b7676 0%, #69687c 100%);
}

.morning {
  background: linear-gradient(0deg, #D9E0E9 0%, #D9E0E9 33%, #EAE9E8 66%, #EAE9E8 100%);
}

.afternoon {
  background: linear-gradient(0deg, #bfc0c5 0%, #c5c5c5 33%, #cbcbca 66%, #d2d0d0 100%);
}

.evening {
  background: linear-gradient(0deg, #b0b1bb 0%, #b6b7bd 33%, #b7b8ba 66%, #bbb8b7 100%);
}

.night {
  background: linear-gradient(0deg, #9c9da9 0%, #a4a5ad 33%, #a8aaae 66%, #a8a3a3 100%);
}

.darkNight {
  background: linear-gradient(0deg, #191919 0%, #1e1e20 33%, #18191d 66%, #181a1f 100%);
}

</style>