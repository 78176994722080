<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" x-show="isOpen">
    <div class="flex items-center justify-center min-h-screen">
      <div class="relative bg-neutral-100 rounded-lg shadow-2xl">
        <div class="p-4 border-b border-gray-200">
          <h3 class="text-lg font-medium text-gray-900">{{ title }}</h3>
          <button @click="$emit('closed')" class="absolute top-2 right-2 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Close</span>
            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div class="p-4">
          <ul class="grid grid-cols-3 gap-4">
            <li v-for="app in apps" :key="app.name" class="relative">
              <img :src="app.logo" :alt="app.name" class="w-16 h-16 object-contain mx-auto mb-2">
              <div class="text-center font-medium">{{ app.name }}</div>
              <button @click="connectApp(app)" class="absolute inset-0 z-10 w-full h-full opacity-0">
                <span class="sr-only">Connect to {{ app.name }}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const emit = defineEmits(['closed'])



const title = 'Connect to External Apps'
const isOpen = ref(false)
const apps = [
  {
    name: 'Notion',
    logo: require('@/assets/Icons/notion-logo.png')
  },
  {
    name: 'Google Docs',
    logo: require('@/assets/Icons/google-docs-logo.png')
  },
  {
    name: 'Google Sheets',
    logo: require('@/assets/Icons/google-sheets-logo.png')
  },
  {
    name: 'LinkedIn',
    logo: require('@/assets/Icons/linkedin-logo.png')
  },
  {
    name: 'Twitter',
    logo: require('@/assets/Icons/twitter-logo.png')
  },
  {
    name: 'Slack',
    logo: require('@/assets/Icons/slack-logo.png')
  },
  {
    name: 'Gmail',
    logo: require('@/assets/Icons/gmail-logo.png')
  },
  {
    name: 'Mailchimp',
    logo: require('@/assets/Icons/mailchimp-logo.png')
  },
  {
    name: 'Wordpress',
    logo: require('@/assets/Icons/wordpress-logo.png')
  }
]


function openModal() {
  isOpen.value = true
}

function closeModal() {
  isOpen.value = false
  context.emit('closed')

}

function connectApp(app) {
  // TODO: open authorization window for app
}

</script>
