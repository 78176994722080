<template>

  <div class="flex justify-center h-full">
    <Onboarding />
  </div>

</template>

<script>

import Onboarding from '@/components/Onboarding.vue'
import { useRouter } from 'vue-router'
import getUser from '../composables/getUser'

import { watch } from 'vue'

export default {
  name: 'Welcome',
  components: {

    Onboarding
  },
  setup() {
    const router = useRouter()
    const {user} = getUser()


    // if the user value is ever null, redirect to welcome screen
    watch(user, () => {
      if (!user.value) {
        router.push({ name: 'Login' })
      }
    })
    
    return {
        user
      }
  }
}

</script>

<style scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>