
const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
  apiKey: process.env.VUE_APP_OPENAI_API_KEY
});

const openai = new OpenAIApi(configuration);

import getUser from '../composables/getUser'
import { getDoc, doc, updateDoc, increment } from "firebase/firestore";
import { db } from '@/firebase/config'


const Edit = async (input, instruction) => {

  const response = await openai.createEdit({
  model: "text-davinci-edit-001",
  input: input,
  instruction: instruction,
  // temperature: 0.8,
  // top_p: 1,
  // n: 1,
  });

  // const response = await openai.createEdit({
  //   model: "text-davinci-edit-001",
  //   input: "What day of the wek is it?",
  //   instruction: "Fix the spelling mistakes",
  // });
  
  //console.log('JSON response: ' + JSON.stringify(response, null, 4));


  let edited = response.data.choices[0].text
  console.log('Edited: ' + edited)

  while (edited[0] == '\n' || edited[0] == ' ') {
    edited = edited.slice(1);
}

  //console.log(edited)
  let usage = response.data.usage.total_tokens
  console.log('Usage: ' + usage)

  //Get User and subtract credits

  const {user} = getUser()

  const docSnap = await getDoc(doc(db, "users", user.value.uid));
  
  
  if (docSnap.exists()) {
    const usageCredits = docSnap.data().usageCredits
    console.log('new usageCredits: ', (usageCredits - usage))
    
  } else {
    console.log("No such document");
  }

  try {
    const docRef = doc(db, "users", user.value.uid);
    await updateDoc(docRef, {
      usageCount: increment(usage),
      usageCredits: increment(-usage)
    })
  } catch (err) {
    console.log(err.message)
  }
   
  return { edited };
    
    }
  
  export default Edit