

<template>

<div class="flex h-screen justify-center">
<div class="form sm:w-1/2 h-screen75 w-screen module relative flex justify-center flex-col flex-nowrap rounded-3xl m-auto bg-neutral-100">

<form class="m-auto mb-0 mt-0 w-5/6" @submit.prevent="handleSubmit">

  <div class="flex m-3 justify-center">
      <h1 class="text-neutral-700 text-4xl font-medium">Sign Up</h1>
  </div>

  <div class="flex justify-center flex-col">
      <div class="flex flex-wrap justify-center mt-6">
        <input type="text" class="m-3 p-2 w-2/3 bg-white bg-opacity-0 border-b-2 border-neutral-200 " placeholder="Name" aria-describedby="Name" required v-model="displayName">
        <input type="email" class="m-3 p-2 w-2/3  bg-white bg-opacity-0 border-b-2 border-neutral-200" placeholder="Email" aria-describedby="emailHelp" required v-model="email">
        <input type="password" class="m-3 w-2/3 p-2 bg-white bg-opacity-0 border-b-2 border-neutral-200" placeholder="Password" required v-model="password">
      </div>
  <div class="flex flex-wrap mt-6 justify-center">
      <button v-if="!isPending" type="submit" class="bg-neutral-50 border rounded m-3 p-2 pl-4 pr-4 w-48">Sign Up</button>
      <button v-if="isPending" type="submit" class="bg-neutral-50 rounded m-3 p-2 pl-4 pr-4 w-48" disabled>Loading</button>
  </div>
      <div class="error">{{ error }}</div>
  </div>
</form>

     <div class="flex flex-wrap justify-center">
      <button class="mt-3" @click="SignInWithGoogle">
        <img src="@/assets/Icons/btn_google_signin_light_normal_web@2x.png" width="191" height="46"/>
      </button>
      </div>

      <div class="flex flex-wrap mt-12 justify-center text-gray-700">
        <button @click="this.$router.push({path: '/login'})" class="p">Or <b class="underline">Log In </b></button>
      </div>

</div>
</div>

<Footer/>

</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useSignup from '../composables/useSignup'
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from '../firebase/config'
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore"; 
import Footer from '../components/Footer.vue'

export default {
  name: 'Signup',
  components: {
    Footer
  },
  setup() {
    const showLogin = ref(false)
    const router = useRouter()
    const displayName = ref('')
    const email = ref('')
    const password = ref('')
    const { error, signup, isPending } = useSignup()

    const handleSubmit = async () => {
      await signup(email.value, password.value, displayName.value)
                if (!error.value) {
                    router.push('/welcome')
                }}

    const SignInWithGoogle = async () => {

      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const user = result.user;

          // Check if user exists in firestore
          const userRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(userRef);

          if (!docSnap.exists()) {
            // Create user in firestore
            await setDoc(userRef, {
              displayName: user.displayName,
              email: user.email,
              createdAt: serverTimestamp(),
              editedAt: serverTimestamp(),
              usageCredits: 100000,
              usageCount: 0,
            });
          }

            router.push('/welcome')

        }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const email = error.email;
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.log(errorCode, errorMessage, email, credential)
        });
    }


    return { showLogin, handleSubmit,  error, displayName, email, password, isPending, SignInWithGoogle}
  
}}
</script>

